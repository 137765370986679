import React, { useState, useEffect, useRef, useCallback, useMemo } from "react";
import Navbar from "./components/Navbar";
import { AiOutlineDown } from "react-icons/ai";
import {
  ApolloProvider,
  ApolloClient,
  InMemoryCache,
  useQuery,
  gql,
  createHttpLink,
} from "@apollo/client";
import { useBalance, useWriteContract, useSwitchChain, useReadContract, useAccount, useConnect} from 'wagmi';
import { getChainId, getAccount} from '@wagmi/core'
import { config } from './config'
import { setContext } from "@apollo/client/link/context";
import { formatUnits, parseUnits } from "ethers/lib/utils";
import ZksyncLogo from './assets/zkSyncZlogo.png';
import ArbLogo from './assets/arb-logo.png';
import MantaLogo from './assets/mantaLogo.png';
import OpLogo from './assets/op-logo.png';
import BlastLogo from './assets/blastLogo.png';
import ScrollLogo from './assets/scroll-logo.png';
import VectorGrid from './assets/vector_grid.svg';
import { contractsByChainID, contractsByName } from './contracts';

const httpLink = createHttpLink({
  uri: "https://lobbyfi.xyz/graphql", //http://localhost:4000/graphql
});

const authLink = setContext((_, { headers }) => {
  // Retrieve the API key from an environment variable or other secure storage
  const apiKey = process.env.REACT_APP_GRAPHQL_API_KEY;
  return {
    headers: {
      ...headers,
      'x-api-key': apiKey || "",
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});


const USER_REWARDS_QUERY = gql`
query UserRewards($wallet: String!) {
    userRewards(wallet: $wallet) {
      token_name
      available_rewards
      network
    }
  }
`;



const MyRewards = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState("ZK");
  //const [delegateAddress, setDelegateAddress] = useState("");
  const { isConnected } = useAccount();
  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);
  const currentChainId = getChainId(config);
  const account = getAccount(config);
  const { connectors, connect } = useConnect();
  
  const dropdownRef = useRef(null);
  const { chainss, switchChain, switchChainAsync} = useSwitchChain();
  const [isLoadingDelegateAddress, setLoadingDelegateAddress] = useState(false);
  
  

  const { lobbyFi, token } = useMemo(() => contractsByName[selectedValue], [selectedValue]);


  //const currentDelegateAddress = useReadContract({

  // const currentDelegateAddress = useReadContract({
  //   address: token.address,
  //   abi: token.abi,
  //   functionName: 'delegates',
  //   args: [account.address],
  //   query: {
  //     notifyOnChangeProps: ['data', 'error'],
  //     refetchOnMount: true,
  //   }
  // });

  const useGetDelegateAddress = () => {
    const { data, isLoading, refetch } = useReadContract({
      address: token.address,
      abi: token.abi,
      functionName: 'delegates',
      args: [account.address],
      query: {
        notifyOnChangeProps: ['data', 'error'],
        refetchOnMount: true,
      }
    });
    return { data, isLoading, refetch };
  };

  // Get the token balance
  const ERC20tokenBalance = useBalance({
    address: account.address,
    token: token.address
  });

  const { data: delegateData, isLoading, refetch: refetchDelegateAddress } = useGetDelegateAddress();

  const formatDelegateAddress = (data) => {
    if (!data) return "No delegation";
    if (Array.isArray(data)) {
      // SCROLL token format
      if (data.length === 0) return "No delegation";
      return data.map(({ _delegatee, _numerator }) => ({
        address: _delegatee.toLowerCase() === lobbyFi.HosterAddress.toLowerCase() ? "LobbyFi" : _delegatee,
        percentage: (Number(_numerator) / 10000) * 100 
      }));
    }
    // Standard format
    return data === '0x0000000000000000000000000000000000000000' ? "No delegation" : 
           (data === account.address ? "Delegated to yourself" : data);
  };

  const isDelegatedToLobbyFi = useMemo(() => {
    if (Array.isArray(delegateData)) {
      // SCROLL token format
        const lobbyFiDelegation = delegateData.find(delegation => 
        delegation._delegatee.toLowerCase() === lobbyFi.HosterAddress.toLowerCase()
      );
      return lobbyFiDelegation && Number(lobbyFiDelegation._numerator) === 10000; // 100% delegation
    }
    // Standard format
    return typeof delegateData === 'string' && 
           typeof lobbyFi?.HosterAddress === 'string' && 
           delegateData.toLowerCase() === lobbyFi.HosterAddress.toLowerCase();
  }, [delegateData, lobbyFi]);

  const { data: hash, isPending, isSuccess, writeContract } = useWriteContract();

  //console.log("currentDelegateAddress", currentDelegateAddress);

  const delegateToLobbyFi = async () => {
    if (isConnected) {
      writeContract({
        address: token.address,
        abi: token.abi,
        functionName: 'delegate',
        args: [lobbyFi.HosterAddress],
      });
      // console.log(hash); // Log the transaction hash
    } else {
      alert("Connect wallet!");
    }
  };

  const claimRewards = async (chainId) => {
    console.log("claiming rewards", chainId);
    if (isConnected) {
      const contractDetails = contractsByChainID[chainId];
      if (!contractDetails) {
        console.error('No contract details found for chain:', chainId);
        return;
      }
      if (chainId !== currentChainId) {
        try {  
          await switchChainAsync({ chainId: chainId });
        } catch (error) {
          console.error('Failed to switch chain:', error);
          return;
        }
      }
      writeContract({
        address: contractDetails.lobbyFi.ProxyAddress,
        abi: contractDetails.lobbyFi.ProxyAbi,
        functionName: 'claimReward', 
      });
      console.log("hash", hash); 
    } else {
      alert("Connect wallet!");
    }
  };

  useEffect(() => {
    if (isSuccess) {
      setLoadingDelegateAddress(true);
      const timeoutId = setTimeout(async () => {
        await refetchDelegateAddress();
        setLoadingDelegateAddress(false);
      }, 5000);
  
      return () => {
        clearTimeout(timeoutId);
        setLoadingDelegateAddress(false);
      };
    }
  }, [isSuccess]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };
  
    window.addEventListener('click', handleClickOutside);
    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const handleSelect = useCallback(async (chain) => {
    setSelectedValue(chain);
    toggleDropdown();
    try {
      switchChain({ chainId: contractsByName[chain].chainId });
    } catch (error) {
      console.error('Failed to switch chain:', error);
    }
  }, [toggleDropdown, switchChain]);

  const chains = [
    { name: 'ZK', logo: ZksyncLogo },
    { name: 'ARB', logo: ArbLogo },
    { name: 'OP', logo: OpLogo },
    { name: 'MANTA', logo: MantaLogo },
    { name: 'BLAST', logo: BlastLogo },
    { name: 'SCROLL', logo: ScrollLogo },
  ];

  const chainsToDropDown = [
    { chainId: 324, dropdown: 'ZK' },
    { chainId: 42161, dropdown: 'ARB' },
    { chainId: 10, dropdown: 'OP' },
    { chainId: 169, dropdown: 'MANTA' },
    { chainId: 81457, dropdown: 'BLAST' },
    { chainId: 534352, dropdown: 'SCROLL' },
  ];

  useEffect(() => {
    const connectedChain = chainsToDropDown.find(chain => chain.chainId === currentChainId);

    if (connectedChain) {
      setSelectedValue(connectedChain.dropdown);
    } else {
      setSelectedValue('ZK'); // defailt to zkSync
      try {
        switchChain({ chainId: currentChainId}); // change to 324 after we added all chains
      } catch (error) {
        console.error('Failed to switch chain:', error);
      }
    }
  }, [currentChainId, switchChain]);

  const selectedChain = useMemo(() => {
    const valueForSelectedChain = selectedValue || 'ZK';
    return chains.find(chain => chain.name === valueForSelectedChain);
  }, [selectedValue, chains]);

  // useEffect(() => {
  //   setSelectedValue(TOKEN_NAMES[chainId] || TOKEN_NAMES.default);
  // }, [chainId]);

  const { data, loading, error1 } = useQuery(USER_REWARDS_QUERY, {
    variables: { wallet: account.address ? account.address.toLowerCase() : "" },
    client,
    skip: !account.address,
  });

  const [rewardPerChain, setChains] = useState([]);
  
  const rewardChains = useMemo(() => {
    if (!data) return [];
    return data.userRewards.reduce((acc, reward) => {
      let chain = acc.find(item => item.network === reward.network);
      if (!chain) {
        chain = { network: reward.network, tokens: [], total_rewards: 0 };
        acc.push(chain);
      }
      let token = chain.tokens.find(item => item.token_name === reward.token_name);
      if (!token) {
        token = { token_name: reward.token_name, available_rewards: 0 };
        chain.tokens.push(token);
      }
      token.available_rewards += reward.available_rewards;
      chain.total_rewards += reward.available_rewards;
      return acc;
    }, []);
  }, [data]);
  
  useEffect(() => {
    setChains(rewardChains);
  }, [rewardChains]);
  

  return (
    <ApolloProvider client={client}>
    <div
    className="bg-black text-[#96fdbf] min-h-screen flex flex-col justify-between" // Added flex and justify-between
    style={{ fontFamily: "var(--body-text-font-family)" }}
    >
      <div className="flex flex-col items-center justify-between w-full">
        <Navbar />
          <div className="text-xs sm:text-base w-full bg-color-[#96fdbf] font-header text-black text-center py-1 my-3" style={{ fontFamily: "var(--body-text-font-family)", backgroundColor: '#96fdbf' }}>
            Delegating season 1 is live, first $10 M delegations get x5 points. {" "} 
            <a href="https://docs.lobbyfi.xyz/governfi-summer-season-1" target="_blank" rel="noopener noreferrer" className="underline hover:text-green-800">
            [read more]
            </a>
          </div>
      </div>
      <div className="max-w-full">
      <div className="max-w-4xl mx-auto px-4">
        <div className="bg-[#343434] rounded-lg shadow-lg p-5 mt-12">
          <div className="flex justify-center gap-4 mt-4">
          <div className="relative w-96 mx-auto flex justify-center"> {/* Increased width */}
  <div className="relative w-full">
    <button
      className="flex items-center justify-between w-full px-4 py-2.5 text-[#96fdbf] hover:text-[#3DFF54] bg-[#96fdbf]/5 hover:bg-gray-800 rounded transition-all border border-[#96fdbf]"
      style={{
        fontFamily: "var(--body-text-font-family)",
      }}
      onClick={(event) => {
        event.stopPropagation();
        toggleDropdown();
      }}
    >
      <div className="flex items-center">
        {selectedChain && (
          <>
            <img src={selectedChain.logo} alt={`${selectedChain.name} logo`} className="w-6 h-6 mr-3" />
            <span className="text-base">ERC20 Token: {selectedChain.name}</span>
          </>
        )}
      </div>
      <AiOutlineDown className={`ml-2 transition-transform duration-200 ${dropdownOpen ? 'rotate-180' : ''}`} />
    </button>

    {dropdownOpen && (
      <div
        ref={dropdownRef}
        className="absolute left-0 right-0 mt-2 bg-black border border-[#96fdbf] rounded-sm shadow-lg shadow-[#96fdbf]/10 z-50 overflow-hidden"
        style={{
          fontFamily: "var(--body-text-font-family)",
        }}
      >
        {/* Title */}
        <div className="px-4 py-3 border-b border-[#373737]">
          <span className="text-[#96fdbf] text-lg font-header">
            Select ERC20 token
          </span>
        </div>

        {/* Network List */}
        <div className="py-2">
          {chains.map((chain) => (
            <button
              key={chain.name}
              onClick={() => handleSelect(chain.name)}
              className="flex items-center w-full px-4 py-3 text-[#96fdbf] hover:text-[#3DFF54] transition-all group"
            >
              <div className="flex items-center w-full bg-[#373737] hover:bg-gray-800 px-4 py-2.5 rounded transition-all">
                <img src={chain.logo} alt={`${chain.name} logo`} className="w-6 h-6 mr-3" />
                <span className="text-base">{chain.name}</span>
              </div>
            </button>
          ))}
        </div>
      </div>
    )}
  </div>
</div>
          </div>
          <div className="flex flex-col md:flex-row justify-center my-4">
            <div className="text-left mx-4">
              {isConnected ? (
                <div className="text-sm sm:text-base text-[#96fdbf] break-words"
                  style={{ fontFamily: "var(--body-text-font-family)" }}>
                  <p>Token Balance: {ERC20tokenBalance.data ? formatUnits(ERC20tokenBalance.data.value, 18) : "Loading..."} {selectedValue}</p>
                  <p>Your account: {account.address}</p>
                  <p>Delegated to: {isLoadingDelegateAddress ? "Loading..." : 
                    (Array.isArray(formatDelegateAddress(delegateData)) 
                      ? formatDelegateAddress(delegateData).map(({ address, percentage }, index) => (
                          <span key={index}>
                            {address} ({percentage.toFixed(2)}%)
                            {index < formatDelegateAddress(delegateData).length - 1 ? ', ' : ''}
                          </span>
                        ))
                      : formatDelegateAddress(delegateData)
                    )
                  }</p>
                </div>
              ) : (
                <p
                  className="text-sm sm:text-base text-[#96fdbf] break-words text-center"
                  style={{ fontFamily: "var(--body-text-font-family)" }}
                >
                  Connect your wallet to see delegation info
                </p>
              )}
            </div>
          </div>
          <div className="mt-auto flex justify-center">
  {isDelegatedToLobbyFi && isConnected ? (
    <p
      className={`w-full mt-4 p-4 rounded text-center bg-[#cccccc] text-[#1A1A1A]`}
      style={{
        fontFamily: "var(--body-text-font-family)",
        cursor: 'default'
      }}
    >
      Already delegated to LobbyFi
    </p>
            ) : 
            <button
              className={`w-full mt-4 p-4 rounded hover:scale-105 ${
                isConnected 
                  ? 'bg-[#96fdbf] text-[#1A1A1A]'
                  : 'bg-[#cccccc] text-[#1A1A1A]'
              }`}
              onClick={async () => {
                if (!isConnected) {
                  // Connect wallet if not connected
                  const connector = connectors[0];
                  if (connector) {
                    try {
                      await connect({ connector });
                      return;
                    } catch (error) {
                      console.error('Connection request reset. Please try again:', error);
                      return;
                    }
                  }
                  return;
                }
                // Existing network switching and delegation logic
                if (account.chainId !== contractsByName[selectedValue].chainId) {
                  try {
                    await switchChainAsync({ chainId: contractsByName[selectedValue].chainId });
                    return;
                  } catch (error) {
                    console.error("Failed to switch network:", error);
                    return;
                  }
                }
                delegateToLobbyFi();
              }}
            >
              {!isConnected
                ? "Connect Wallet"
                : account.chainId !== contractsByName[selectedValue].chainId
                ? "Switch Network"
                : isPending 
                ? 'Loading...' 
                : 'Delegate to LobbyFi'}
            </button>
            }
          </div>
        </div>
        </div>
      </div>
      <div className="max-w-full overflow-x-hidden">
        <div className="max-w-4xl mx-auto px-4">
          <div className="bg-[#343434] rounded-lg shadow-lg p-5 mt-12">
          <h1 className="text-xl sm:text-2xl" style={{ fontFamily: "var(--body-text-font-family)", color: "#96fdbf", textAlign: "center", fontSize: "1.5em" }}>
            Your rewards
          </h1>
            <div className="relative mx-auto flex flex-col justify-center">
            {!isConnected || (rewardPerChain.length === 0 && !loading) || !rewardPerChain.some(chain => chain.total_rewards > 0) ? (
              <p className="text-sm sm:text-base text-center mx-auto">No rewards available</p>
            )  : (
                <>
                  <div className="border-b border-[#96fdbf] my-4 p-2 overflow-x-auto"> 
                    <table className="w-full">
                      <thead>
                        <tr className="flex justify-between">
                          <th className="w-1/3 text-sm sm:text-base text-left">Token</th>
                          <th className="w-1/3 text-sm sm:text-base text-right">Claimable Rewards</th>
                          <th className="w-1/3 text-sm sm:text-base"></th>
                        </tr>
                      </thead>
                    </table>
                  </div>
                  <div className="w-full overflow-x-hidden">
                    {rewardPerChain.map((chain, chainIndex) => (
                      <div key={chain.network} className="border-2 border-dashed border-[#96fdbf] my-2 p-2 rounded">
                        <div className="w-full">
                          <table className="w-full table-auto">
                            <tbody>
                              {loading ? (
                                <tr><td>Loading...</td></tr>
                              ) : (
                                <>
                                  {chain.tokens.map((token, index) => (
                                    <tr key={`${chain.network}-${token.token_name}`} className="flex justify-between items-center py-1">
                                      <td className="w-1/3 text-sm sm:text-base text-left">{token.token_name}</td>
                                      <td className="w-1/3 text-sm sm:text-base text-right">{(Math.round((token.available_rewards / 1e18) * 1e8) / 1e8).toFixed(8)} ETH</td>
                                      <td className="w-1/3"></td>
                                    </tr>
                                  ))}
                                  <tr key={`${chain.network}-total`} className="flex justify-between items-center py-1 bg-black">
                                    <td className="w-1/3 text-sm sm:text-base text-left">Total</td>
                                    <td className="w-1/3 text-sm sm:text-base text-right">{(Math.round((chain.total_rewards / 1e18) * 1e8) / 1e8).toFixed(8)} ETH</td>
                                    <td className="w-1/3 text-sm sm:text-base text-right">
                                    <button 
                                      onClick={async () => {
                                        if (account.chainId !== chain.network) {
                                          try {
                                            await switchChainAsync({ chainId: chain.network });
                                            return;
                                          } catch (error) {
                                            console.error("Failed to switch network:", error);
                                            return;
                                          }
                                        }
                                        claimRewards(chain.network);
                                      }}
                                      disabled={!isConnected}
                                      className={`whitespace-normal break-words ${
                                        !isConnected
                                          ? 'text-gray-400 cursor-not-allowed'
                                          : account.chainId !== chain.network
                                          ? 'text-yellow-500 hover:text-yellow-600'
                                          : 'hover:text-[#3DFF54] cursor-pointer'
                                      }`}
                                    >
                                      {!isConnected
                                        ? "Connect Wallet"
                                        : account.chainId !== chain.network
                                        ? "Switch Network"
                                        : "Claim Rewards"}
                                    </button>
                                    </td>
                                  </tr>
                                </>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="max-w-full overflow-x-hidden">
        <div className="max-w-4xl mx-auto px-4">
          <div className="bg-[#343434] rounded-lg shadow-lg p-5 mt-12">
            <h1 className="text-xl sm:text-2xl" style={{ fontFamily: "var(--body-text-font-family)", color: "#96fdbf", textAlign: "center", fontSize: "1.5em" }}>
              Your LobbyFi points
            </h1>
            <p className="text-sm sm:text-base" style={{ fontFamily: "var(--body-text-font-family)", color: "#96fdbf", textAlign: "center", paddingTop: "1em" }}>
              ...will appear here soon.
            </p>
          </div>
        </div>
      </div>
        {/* Footer */}
        <div className="flex flex-col items-end justify-center w-full mt-64">
          <div className="flex justify-between items-center w-full px-[2px] py-2 bg-black">
            {/* Text */}
            <p className="text-xs sm:text-base font-body-text text-[#96fdbf] tracking-[1.00px] leading-[20px]"
              style={{ fontFamily: 'var(--body-text-font-family)' }}>
              © 2025 LobbyFi. All rights reserved.
            </p>
          </div>

          {/* Grid Component */}
          <div className="w-full  bg-black">
            <img src={VectorGrid} alt="Retro Vector Grid" className="w-full" />
          </div>
        </div>
    </div>
    </ApolloProvider>
  );
};

export default MyRewards;
