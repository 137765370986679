import React, { useState, useMemo, useEffect } from "react";
import { useParams } from "react-router-dom";
import Navbar from "./components/Navbar";
import InfoBar from "./components/InfoLine";
import VectorGrid from "./assets/vector_grid.svg";
import { config } from "./config";
import {
  useBalance,
  useWriteContract,
  useSwitchChain,
  useAccount,
  useWaitForTransactionReceipt,
  useContractRead,
  useSendTransaction,
  WagmiProvider,
  useConnect,
  useSwitchNetwork,
} from "wagmi";
import { parseEther, formatEther, parseUnits } from "ethers/lib/utils";
import {
  ApolloProvider,
  ApolloClient,
  InMemoryCache,
  useQuery,
  gql,
  createHttpLink,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";

const INITIAL_PROPOSAL_QUERY = gql`
  query YapProposal($id: ID!) {
    yapProposal(id: $id) {
      id
      name
      price_per_vote
      start_timestamp
      end_timestamp
      status
      validated_votes
      required_votes
    }
    yapProposals(status: 0) {
      id
      price_per_vote
      end_timestamp
    }
  }
`;

const httpLink = createHttpLink({
  uri: "https://lobbyfi.xyz/graphql", //http://localhost:4000/graphql https://lobbyfi.xyz/graphql
});

const authLink = setContext((_, { headers }) => {
  // Retrieve the API key from an environment variable or other secure storage
  const apiKey = process.env.REACT_APP_GRAPHQL_API_KEY;
  return {
    headers: {
      ...headers,
      "x-api-key": apiKey || "",
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

const YapProposalShow = () => {
  const { id } = useParams();
  const { address, isConnected, chainId: addressChainId } = useAccount();
  const [pricePerVote, setPricePerVote] = useState("");
  const [totalBudget, setTotalBudget] = useState("");
  const [approvalTxHash, setApprovalTxHash] = useState(null);
  const { chainss, switchChain, switchChainAsync } = useSwitchChain();
  const [depositTxHash, setDepositTxHash] = useState(null);
  const [approvalStatus, setApprovalStatus] = useState("NotStarted");
  const [depositStatus, setDepositStatus] = useState("NotStarted");
  const { connect, connectors } = useConnect();
  const {
    writeContract: approveUSDC,
    data: approvalData,
    isLoading: isApprovalLoading,
    isSuccess: isApprovalSuccess,
    error: approvalError,
  } = useWriteContract();
  const {
    writeContract: depositUSDC,
    data: depositData,
    isLoading: isDepositLoading,
    isSuccess: isDepositSuccess,
    error: depositError,
  } = useWriteContract();
  const {
    data: approvalReceipt,
    isLoading: isWaitingForApproval,
    isSuccess: isApprovalConfirmed,
    error: waitForApprovalError,
  } = useWaitForTransactionReceipt({
    hash: approvalData?.hash,
  });
  const {
    data: depositReceipt,
    isLoading: isWaitingForDeposit,
    isSuccess: isDepositConfirmed,
    error: waitForDepositError,
  } = useWaitForTransactionReceipt({
    hash: depositData?.hash,
  });

  const { data: proposalData, loading } = useQuery(INITIAL_PROPOSAL_QUERY, {
    variables: { id },
    client,
  });

  // Get the allowance of USDC for the LobbyFi contract
  const { data: allowanceData, refetch: refetchAllowance } = useContractRead({
    address: "0xaf88d065e77c8cc2239327c5edb3a432268e5831",
    abi: [
      {
        constant: true,
        inputs: [
          { name: "_owner", type: "address" },
          { name: "_spender", type: "address" },
        ],
        name: "allowance",
        outputs: [{ name: "", type: "uint256" }],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
    ],
    functionName: "allowance",
    args: [address, "0x992F1147D5aE5e61627Bc4C4DEB56AD647cF2644"], // Owner, Spender (LobbyFi contract)
    watch: true,
    onError(error) {
      console.error("Error reading allowance:", error);
    },
  });

  const highestPricePerVote = useMemo(() => {
    if (!proposalData?.yapProposals || !proposalData?.yapProposal) return 0;

    // Filter proposals with same end_timestamp as current proposal
    const sameGroupProposals = proposalData.yapProposals.filter(
      (p) => p.end_timestamp === proposalData.yapProposal.end_timestamp
    );

    return Math.max(...sameGroupProposals.map((p) => Number(p.price_per_vote)));
  }, [proposalData]);

  const [errorMessage, setErrorMessage] = useState("");

  const requiredAllowance = useMemo(() => {
    if (totalBudget) {
      return parseUnits(totalBudget, 6); // USDC has 6 decimals
    }
    return BigInt(0);
  }, [totalBudget]);

  const isApproved = useMemo(() => {
    if (!allowanceData) return false;
    return allowanceData >= requiredAllowance;
  }, [allowanceData, requiredAllowance]);

  // Update handleSubmit to handle both approval and deposit
  const handleSubmit = async () => {
    setErrorMessage("");

    if (isApproved) {
      // If already approved, proceed with deposit
      setApprovalStatus("Approved");
      initiateDeposit();
    } else {
      // If not approved, initiate approval
      try {
        setApprovalStatus("Pending");
        const priceInWei = parseUnits(pricePerVote, 6);
        const totalBudgetInWei = parseUnits(totalBudget, 6);

        // console.log("Attempting USDC approval...");
        approveUSDC({
          address: "0xaf88d065e77c8cc2239327c5edb3a432268e5831", // USDC contract on Sepolia
          abi: [
            {
              inputs: [
                { internalType: "address", name: "spender", type: "address" },
                { internalType: "uint256", name: "amount", type: "uint256" },
              ],
              name: "approve",
              outputs: [{ internalType: "bool", name: "", type: "bool" }],
              stateMutability: "nonpayable",
              type: "function",
            },
          ],
          functionName: "approve",
          args: [
            "0x992F1147D5aE5e61627Bc4C4DEB56AD647cF2644",
            totalBudgetInWei,
          ],
        });
      } catch (error) {
        console.error("Error in handleSubmit:", error);
        setErrorMessage(
          error.message?.includes("user rejected")
            ? "Transaction was rejected"
            : `Error: ${error.message || "Unknown error occurred"}`
        );
        setApprovalStatus("NotStarted");
      }
    }
  };

  const initiateDeposit = () => {
    try {
      setDepositStatus("Pending");
      const priceInWei = parseUnits(pricePerVote, 6);
      const totalBudgetInWei = parseUnits(totalBudget, 6);

      // console.log("Starting USDC deposit...");
      depositUSDC({
        address: "0x992F1147D5aE5e61627Bc4C4DEB56AD647cF2644", // LobbyFi contract on Sepolia
        abi: [
          {
            inputs: [
              { internalType: "string", name: "_poolName", type: "string" },
              {
                internalType: "uint256",
                name: "_pricePerVote",
                type: "uint256",
              },
              { internalType: "uint256", name: "_amount", type: "uint256" },
            ],
            name: "depositUSDC",
            outputs: [],
            stateMutability: "nonpayable",
            type: "function",
          },
        ],
        functionName: "depositUSDC",
        args: [proposalData.yapProposal.id, priceInWei, totalBudgetInWei],
      });
    } catch (error) {
      console.error("Error in deposit:", error);
      setErrorMessage(getShortenedErrorMessage(error));
      setDepositStatus("NotStarted");
    }
  };

  useEffect(() => {
    if (isApprovalSuccess && approvalData) {
      setApprovalTxHash(approvalData.hash);
      // console.log("Approval transaction sent:", approvalData.hash);
    }
  }, [isApprovalSuccess, approvalData]);

  useEffect(() => {
    if (isApprovalConfirmed && approvalReceipt) {
      // console.log("Approval confirmed:", approvalReceipt);
      setApprovalStatus("Approved"); // Update approval status
      refetchAllowance();
      initiateDeposit();
    }
  }, [isApprovalConfirmed, approvalReceipt, refetchAllowance]);

  useEffect(() => {
    if (isDepositSuccess && depositData) {
      setDepositTxHash(depositData.hash);
      // console.log("Deposit transaction sent:", depositData.hash);
    }
  }, [isDepositSuccess, depositData]);

  // useEffect(() => {
  //     if (isDepositConfirmed && depositReceipt) {
  //     console.log("Deposit confirmed:", depositReceipt);
  //     refetchProposalData();
  //     }
  // }, [isDepositConfirmed, depositReceipt, refetchProposalData]);

  useEffect(() => {
    if (approvalError) {
      console.error("Approval error:", approvalError);
      setErrorMessage(approvalError.message || "Approval failed");
      setApprovalStatus("NotStarted");
    }
    if (depositError) {
      console.error("Deposit error:", depositError);
      setErrorMessage(depositError.message || "Deposit failed");
    }
  }, [approvalError, depositError]);

  useEffect(() => {
    if (isDepositSuccess && depositData) {
      //   console.log("Deposit transaction sent:", depositData.hash);
    }
  }, [isDepositSuccess, depositData]);

  useEffect(() => {
    if (isDepositConfirmed && depositReceipt) {
      //   console.log("Deposit confirmed:", depositReceipt);
    }
  }, [isDepositConfirmed, depositReceipt]);

  useEffect(() => {
    if (approvalError) {
      console.error("Approval error:", approvalError);
      setErrorMessage(approvalError.message || "Approval failed");
    }
    if (depositError) {
      console.error("Deposit error:", depositError);
      setErrorMessage(depositError.message || "Deposit failed");
    }
  }, [approvalError, depositError]);

  useEffect(() => {
    if (approvalError) {
      console.error("Approval error:", approvalError);
      setErrorMessage(getShortenedErrorMessage(approvalError)); // Use shortened message
      setApprovalStatus("NotStarted");
    }
    if (depositError) {
      console.error("Deposit error:", depositError);
      setErrorMessage(getShortenedErrorMessage(depositError)); // Use shortened message
    }
  }, [approvalError, depositError]);

  const getShortenedErrorMessage = (error) => {
    if (!error?.message) return "Unknown error occurred";

    if (error.message.includes("User rejected")) {
      return "Transaction rejected by user";
    }

    if (error.message.includes("insufficient funds")) {
      return "Insufficient funds for gas";
    }

    if (error.message.includes("Pool is not active")) {
      return "Pool is not active";
    }

    if (error.message.includes("execution reverted")) {
      const revertReason = error.message.match(
        /reverted with reason string '(.*?)'/
      );
      if (revertReason && revertReason[1]) {
        return `Transaction failed: ${revertReason[1]}`;
      }
      return "Transaction failed due to contract error";
    }

    return error.message;
  };

  if (loading) {
    return (
      <div
        className="bg-black min-h-screen text-[#96fdbf] p-8"
        style={{ fontFamily: "var(--body-text-font-family)" }}
      >
        <div className="max-w-4xl mx-auto">
          <div className="bg-[#343434] rounded-lg shadow-lg p-8">
            <p className="text-center">Loading proposal details...</p>
          </div>
        </div>
      </div>
    );
  }

  const proposal = proposalData?.yapProposal;

  return (
    <div
      className="bg-black min-h-screen text-[#96fdbf]"
      style={{ fontFamily: "var(--body-text-font-family)" }}
    >
      <div className="flex flex-col items-center justify-between w-full">
        <Navbar />
        <InfoBar />
      </div>
      <div className="max-w-4xl mx-auto mt-12">
        <div className="bg-[#343434] rounded-lg shadow-lg p-8">
          {/* Proposal Header */}
          <div className="mb-8">
            <h1 className="text-3xl font-bold mb-2">{proposal.name}</h1>
          </div>

          {/* Deposit Form */}
          <form onSubmit={handleSubmit} className="space-y-6 ">
            <div className="bg-black/30 p-6 rounded-lg border border-[#96fdbf]/30">
              <h2 className="text-xl font-bold mb-6">Add Budget for Votes</h2>
              {/* Price Per Vote Input */}
              <div className="mb-6">
                <label className="block text-sm text-gray-400 mb-2">
                  Price per Vote (USDC)
                </label>
                <div className="relative">
                  <input
                    type="number"
                    step="0.00001"
                    min="0"
                    value={pricePerVote}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (value === "" || Number(value) >= 0) {
                        setPricePerVote(value);
                      }
                    }}
                    className="w-full bg-black border border-[#96fdbf] rounded-lg px-4 py-3 text-[#96fdbf] focus:outline-none focus:ring-2 focus:ring-[#96fdbf]"
                    placeholder="0.001"
                    required
                  />
                  <span className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-400">
                    USDC
                  </span>
                </div>
                {/* Highest Bid Info */}
                <div className="mt-2 p-3 bg-black/50 rounded-lg border border-[#96fdbf]/30">
                  <div className="flex justify-between items-center">
                    <span className="text-sm text-gray-400">
                      Current highest bid:
                    </span>
                    <span className="text-[#96fdbf] font-bold">
                      {(highestPricePerVote / 10 ** 6).toFixed()} USDC
                    </span>
                  </div>
                  <p className="text-xs text-gray-400 mt-1">
                    Bid higher than this to maximize your chance of getting
                    votes
                  </p>
                </div>
              </div>

              {/* Total Budget Input */}
              <div className="mb-6">
                <label className="block text-sm text-gray-400 mb-2">
                  Total Budget (USDC)
                </label>
                <div className="relative">
                  <input
                    type="number"
                    step="0.0000001"
                    min="0"
                    value={totalBudget}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (value === "" || Number(value) >= 0) {
                        setTotalBudget(value);
                      }
                    }}
                    className="w-full bg-black border border-[#96fdbf] rounded-lg px-4 py-3 text-[#96fdbf] focus:outline-none focus:ring-2 focus:ring-[#96fdbf]"
                    placeholder="1.0"
                    required
                  />
                  <span className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-400">
                    USDC
                  </span>
                </div>
              </div>

              {/* Estimated Votes */}
              {pricePerVote && totalBudget && (
                <div className="mb-6 p-4 bg-[#96fdbf]/10 rounded-lg">
                  <p className="text-sm text-gray-400">
                    Estimated votes you can buy:{" "}
                    <span className="text-[#96fdbf] font-bold">
                      {Math.floor(Number(totalBudget) / Number(pricePerVote))}{" "}
                      Yaps
                    </span>
                  </p>
                </div>
              )}

              {/* Submit Button */}
              <button
                type="button"
                onClick={async () => {
                  if (!isConnected) {
                    // Connect wallet if not connected
                    const connector = connectors[0];
                    if (connector) {
                      try {
                        await connect({ connector });
                      } catch (error) {
                        console.error(
                          "Connection request reset. Please try again:",
                          error
                        );
                      }
                    }
                    return;
                  }

                  // Switch network if not on Arbitrum
                  if (addressChainId !== 42161) {
                    try {
                      await switchChainAsync({ chainId: 42161 });
                    } catch (error) {
                      console.error("Failed to switch network:", error);
                    }
                    return;
                  }

                  handleSubmit();
                }}
                disabled={
                  isApprovalLoading ||
                  isDepositLoading ||
                  isWaitingForApproval ||
                  isWaitingForDeposit
                }
                className={`w-full py-4 rounded-lg transition-all duration-300 ${
                  isConnected && !isApproved
                    ? "bg-[#96fdbf] text-black hover:bg-[#7fdba7] hover:scale-105"
                    : isConnected && isApproved
                    ? "bg-[#96fdbf] text-black hover:bg-[#7fdba7] hover:scale-105"
                    : "bg-gray-600 text-gray-300 hover:bg-[#687a70] hover:scale-105"
                }`}
              >
                {!isConnected
                  ? "Connect Wallet"
                  : addressChainId !== 42161
                  ? "Switch to Arbitrum"
                  : isApprovalLoading
                  ? "Approving USDC..."
                  : isWaitingForApproval
                  ? "Waiting for Approval..."
                  : isDepositLoading
                  ? "Depositing..."
                  : isWaitingForDeposit
                  ? "Waiting for Deposit..."
                  : isApproved
                  ? "Deposit USDC"
                  : "Approve and Deposit"}
              </button>
              {/* Error Message */}
              {errorMessage && (
                <p className="mt-4 text-red-500 text-sm">
                  Error: {errorMessage}
                </p>
              )}
              {/* Transaction Hashes */}
              {approvalTxHash && approvalStatus === "Approved" && (
                <div className="mt-4">
                  <p className="text-sm text-gray-400">
                    Approval Transaction Hash:
                  </p>
                  <a
                    href={`https://sepolia.etherscan.io/tx/${approvalTxHash}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-green-500 text-sm break-all hover:underline"
                  >
                    {approvalTxHash}
                  </a>
                </div>
              )}

              {depositTxHash && depositStatus === "Deposited" && (
                <div className="mt-4">
                  <p className="text-sm text-gray-400">
                    Deposit Transaction Hash:
                  </p>
                  <a
                    href={`https://sepolia.etherscan.io/tx/${depositTxHash}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-green-500 text-sm break-all hover:underline"
                  >
                    {depositTxHash}
                  </a>
                </div>
              )}
            </div>
          </form>

          {/* Additional Info */}
          <div className="mt-8 text-sm text-gray-400">
            <p>
              Note: Once deposited, USDC cannot be withdrawn in the next 30
              days.
            </p>
          </div>
        </div>
        {/* Add this after the form, before Additional Info */}
        <div className="mt-8 bg-[#343434] p-6 rounded-lg">
          <h2 className="text-xl font-bold mb-6">Current lobbying state</h2>

          {/* Stats Grid */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-6">
            <div className="bg-black/30 p-4 rounded-lg">
              <p className="text-sm text-gray-400 mb-1 text-center">
                Total Verified Votes
              </p>
              <p className="text-2xl text-[#96fdbf] font-bold text-center">
                {proposal.validated_votes.toLocaleString()} Yaps
              </p>
            </div>
            <div className="bg-black/30 p-4 rounded-lg">
              <p className="text-sm text-gray-400 mb-1 text-center">
                Required Votes
              </p>
              <p className="text-2xl text-[#96fdbf] font-bold text-center">
                {proposal.required_votes.toLocaleString()} Yaps
              </p>
            </div>
            <div className="bg-black/30 p-4 rounded-lg">
              <p className="text-sm text-gray-400 mb-1 text-center">Progress</p>
              <p className="text-2xl text-[#96fdbf] font-bold text-center">
                {(
                  (proposal.validated_votes / proposal.required_votes) *
                  100
                ).toFixed(1)}
                %
              </p>
            </div>
          </div>

          {/* Progress Bar */}
          <div className="relative w-full h-8 bg-black/30 rounded-full overflow-hidden">
            <div
              className="absolute top-0 left-0 h-full bg-[#96fdbf] rounded-full transition-all duration-500"
              style={{
                width: `${Math.min(
                  (proposal.validated_votes / proposal.required_votes) * 100,
                  100
                )}%`,
                boxShadow: "0 0 20px rgba(150, 253, 191, 0.3)",
              }}
            />
            <div className="absolute w-full h-full flex items-center justify-center text-sm font-bold">
              {(
                (proposal.validated_votes / proposal.required_votes) *
                100
              ).toFixed(1)}
              %
            </div>
          </div>

          {/* Additional Stats */}
          <div className="mt-6 text-sm text-gray-400">
            <p>
              Still needed:{" "}
              <span className="text-[#96fdbf] font-bold">
                {Math.max(
                  0,
                  proposal.required_votes - proposal.validated_votes
                ).toLocaleString()}{" "}
                Yaps
              </span>
            </p>
          </div>
        </div>
      </div>
      {/* Footer */}
      <div className="flex flex-col items-end justify-center w-full mt-24">
        <div className="flex justify-between items-center w-full px-[2px] py-2 bg-black">
          <p className="text-xs sm:text-base font-body-text text-[#96fdbf] tracking-[1.00px] leading-[20px]">
            © 2025 LobbyFi. All rights reserved.
          </p>
        </div>
        <div className="w-full bg-black">
          <img src={VectorGrid} alt="Retro Vector Grid" className="w-full" />
        </div>
      </div>
    </div>
  );
};

export default YapProposalShow;
