// Add global error handlers
window.addEventListener('error', function(event) {
  console.log('Error:', event.error);
  event.preventDefault();
  return true;
}, true);  // Add 'true' for capturing phase

window.addEventListener('unhandledrejection', function(event) {
  console.log('Unhandled Promise Rejection:', event.reason);
  event.preventDefault();
  return true;
}, true);

import React from "react";
import { createRoot } from "react-dom/client";
// import { DAppProvider } from "@usedapp/core";
import { WagmiProvider } from 'wagmi'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

import App from "./App";
import { config } from "./config";

import "./index.css";

// Add the CustomErrorBoundary component
class CustomErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    console.log('Error caught:', error);
    return null;
  }

  componentDidCatch(error, errorInfo) {
    console.log('Error:', error);
    console.log('ErrorInfo:', errorInfo);
  }

  render() {
    return this.props.children;
  }
}

const container = document.getElementById("root");
const root = createRoot(container);

const queryClient = new QueryClient()

root.render(
  <React.StrictMode>
    <CustomErrorBoundary>
      {/* <DAppProvider config={DAPP_CONFIG}> */}
      <WagmiProvider config={config}>
        <QueryClientProvider client={queryClient}>
          <App />
        </QueryClientProvider>
      </WagmiProvider>
      {/* </DAppProvider> */}
    </CustomErrorBoundary>
  </React.StrictMode>
);



// import React from "react";
// import ReactDOM from "react-dom";
// import { DAppProvider } from "@usedapp/core";

// import App from "./App";
// import { DAPP_CONFIG } from "./config";

// import "./index.css";

// ReactDOM.render(
//   <React.StrictMode>
//     <DAppProvider config={DAPP_CONFIG}>
//       <App />
//     </DAppProvider>
//   </React.StrictMode>,
//   document.getElementById("root")
// );