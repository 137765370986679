import React from 'react';
import { useNavigate } from "react-router-dom";

const Header = () => {
    const navigate = useNavigate();

    const handleYapsClick = () => {
        navigate('/yaps');
    };

    return (
        <div className="text-xs sm:text-base w-full bg-color-[#96fdbf] font-header text-black text-center py-1 my-3" style={{ fontFamily: "var(--body-text-font-family)", backgroundColor: '#96fdbf' }}>
            Kaito Yap voting power trading is live! {'   '}
            <button 
                onClick={handleYapsClick}
                className="underline hover:text-green-800 cursor-pointer"
            >
                [Buy / sell Yaps votes]
            </button>
        </div>
    );
};

export default Header;