import React, { useState, useEffect } from 'react';
import { Wallet, Lock, ArrowRight, Shield, CheckCircle2, Workflow, Gavel, Vote, Award, Info } from 'lucide-react';
import LobbyFiLogo from '../assets/LobbyFi.png';

const TokenSafetySection = () => {
  const [animate, setAnimate] = useState(false);
  const [lineAnimate, setLineAnimate] = useState(false);

  useEffect(() => {
    // Start animation after component mount
    setAnimate(true);
    // Reset animation every 5 seconds
    const interval = setInterval(() => {
      setAnimate(false);
      setTimeout(() => setAnimate(true), 100);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    // Initial animation
    setLineAnimate(true);
    
    const lineInterval = setInterval(() => {
      setLineAnimate(false);
      // Increased delay before setting back to true
      setTimeout(() => setLineAnimate(true), 500); // Increased from 100 to 500
    }, 3000); // Increased from 2000 to 3000 for smoother animation

    return () => clearInterval(lineInterval);
  }, []);

  return (
    <div className="w-full py-6 px-4 bg-transparent" style={{ fontFamily: "var(--body-text-font-family)" }}>
      <div className="max-w-6xl mx-auto">
        {/* Main Header */}
        <div className="text-center mb-12">
          <div className="inline-block border-2 border-[#96fdbf]/30 rounded-full px-6 py-2 mb-6">
            <div className="flex items-center gap-2 text-[#96fdbf]/80">
              <Workflow className="w-5 h-5" />
              <span className="text-sm font-medium">How Delegation Works</span>
            </div>
          </div>
          <h2 className="text-4xl font-bold text-[#96fdbf] mb-6">100% Non-Custodial Delegation</h2>
          <p className="text-[#96fdbf]/70 max-w-2xl mx-auto text-lg">
            Your tokens always remain safely in self-custody while you delegate your voting power via the token smart contract.
          </p>
        </div>

        {/* Delegation Flow Visualization */}
        <div className="flex flex-col items-center justify-center mb-20">
          <div className="relative w-full max-w-4xl">
            {/* Main Flow Container */}
            <div className="relative flex flex-col md:flex-row items-center justify-center gap-4 md:gap-24">
              {/* Wallet Card */}
              <div className={`
                w-full max-w-md transform transition-all duration-1000 ease-in-out
                ${animate ? 'translate-y-0 opacity-100' : 'translate-y-4 opacity-0'}
              `}>
                <div className="border-2 border-[#96fdbf] rounded-lg p-8 bg-black/40 
                              shadow-[0_0_20px_rgba(150,253,191,0.2)] backdrop-blur-sm h-[300px]">
                  <div className="flex flex-col items-center h-full">
                    <Wallet className="w-16 h-16 text-[#96fdbf] mb-8" />
                    <div className="text-center flex-grow">
                      <h3 className="text-2xl font-bold text-[#96fdbf] mb-2">Your Wallet</h3>
                      <p className="text-[#96fdbf]/80">Tokens remain securely in your custody</p>
                    </div>
                    <div className="inline-flex items-center justify-center gap-3 border-2 border-[#96fdbf]/30 rounded-full px-4 py-2">
                      <Lock className="w-5 h-5 text-[#96fdbf] flex-shrink-0" />
                      <span className="text-[#96fdbf]/90 text-sm text-center">Full token custody</span>
                    </div>
                  </div>
                </div>
              </div>

              {/* Connection Lines Container */}
              <div className="relative py-8 md:py-0">
                <div className="flex flex-row md:flex-col gap-4">
                  {/* First Line - Delegate voting power */}
                  <div className={`
                    transform transition-all duration-1000 ease-in-out
                    ${animate ? 'translate-y-0 opacity-100' : 'translate-y-4 opacity-0'}
                  `}>
                    {/* Vertical line for mobile */}
                    <div className="md:hidden flex flex-col items-center gap-2">
                      <div className="relative h-16">
                        {/* Base vertical line */}
                        <div className="absolute h-full w-0.5 bg-[#96fdbf]/30"></div>
                        
                        {/* Animated vertical line */}
                        <div className={`
                          absolute w-0.5 bg-[#96fdbf]
                          transition-all duration-1000 ease-in-out
                          ${lineAnimate ? 'h-full opacity-100' : 'h-0 opacity-0'}
                        `}></div>
                      </div>
                      
                      {/* Mobile label */}
                      <span className="text-[#96fdbf]/70 text-sm text-center w-24 break-words">
                        Delegate voting power
                      </span>
                    </div>

                    {/* Horizontal line for desktop */}
                    <div className="hidden md:block relative w-24">
                      {/* Base line */}
                      <div className="absolute top-1/2 w-full h-0.5 bg-[#96fdbf]/30"></div>
                      
                      {/* Animated line */}
                      <div className={`
                        absolute top-1/2 h-0.5 bg-[#96fdbf]
                        transition-all duration-1000 ease-in-out
                        ${lineAnimate ? 'w-full opacity-100' : 'w-0 opacity-0'}
                      `}></div>

                      {/* Label */}
                      <div className="absolute -top-8 left-1/2 transform -translate-x-1/2 whitespace-nowrap">
                        <span className="text-[#96fdbf]/70 text-sm">Delegate voting power</span>
                      </div>
                    </div>
                  </div>

                  {/* Second Line - Receive ETH rewards */}
                  <div className={`
                    transform transition-all duration-1000 ease-in-out md:mt-8
                    ${animate ? 'translate-y-0 opacity-100' : 'translate-y-4 opacity-0'}
                  `}>
                  {/* Mobile Vertical line for Receive ETH rewards */}
                    <div className="md:hidden flex flex-col items-center gap-2">
                      <div className="relative h-16">
                        {/* Base vertical line */}
                        <div className="absolute h-full w-0.5 bg-[#96fdbf]/30"></div>
                        
                        {/* Animated vertical line */}
                        <div className={`
                          absolute w-0.5 bg-[#96fdbf] bottom-0
                          transition-all duration-1000 ease-in-out
                          ${lineAnimate ? 'h-full opacity-100' : 'h-0 opacity-0'}
                          origin-bottom
                        `}></div>
                      </div>
                      
                      {/* Mobile label */}
                      <span className="text-[#96fdbf]/70 text-sm text-center w-24 break-words">
                        Receive ETH rewards
                      </span>
                    </div>

                    {/* Horizontal line for desktop */}
                    <div className="hidden md:block relative w-24">
                      {/* Base line */}
                      <div className="absolute top-1/2 w-full h-0.5 bg-[#96fdbf]/30"></div>
                      
                      {/* Animated line */}
                      <div className={`
                        absolute top-1/2 right-0 h-0.5 bg-[#96fdbf]
                        transition-all duration-1000 ease-in-out
                        ${lineAnimate ? 'w-full opacity-100' : 'w-0 opacity-0'}
                        transform origin-right
                      `}></div>

                      {/* Label */}
                      <div className="absolute -bottom-8 left-1/2 transform -translate-x-1/2 whitespace-nowrap">
                        <span className="text-[#96fdbf]/70 text-sm">Receive ETH rewards</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* LobbyFi Card */}
              <div className={`
                w-full max-w-md transform transition-all duration-1000 ease-in-out delay-500
                ${animate ? 'translate-y-0 opacity-100' : 'translate-y-4 opacity-0'}
              `}>
                <div className="border-2 border-[#96fdbf] rounded-lg p-8 bg-black/40 
                              shadow-[0_0_20px_rgba(150,253,191,0.2)] backdrop-blur-sm h-[300px]">
                  <div className="flex flex-col items-center h-full">
                    <img 
                      src={LobbyFiLogo} 
                      alt="LobbyFi Logo" 
                      className="w-44 h-44 object-contain mb-2"
                    />
                    <div className="text-center flex-grow mb-1"> {/* Added mb-8 here */}
                      <p className="text-[#96fdbf]/80">Generates revenue with received voting power</p>
                    </div>
                    <div className="inline-flex items-center justify-center gap-3 border-2 border-[#96fdbf]/30 rounded-full px-4 py-2">
                      <CheckCircle2 className="w-5 h-5 text-[#96fdbf] flex-shrink-0" />
                      <span className="text-[#96fdbf]/90 text-sm text-center">Voting rights only</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className={`
              text-center mt-8 max-w-2xl mx-auto
              transform transition-all duration-700 ease-in-out delay-1000
              ${animate ? 'translate-y-0 opacity-100' : 'translate-y-4 opacity-0'}
            `}>
              <div className="flex items-start justify-center gap-2">
                <Info className="w-4 h-4 text-[#96fdbf] flex-shrink-0 mt-0.5" />
                <p className="text-[#96fdbf]/80 text-sm text-center">
                  Your tokens are delegated directly through ERC-20 token contracts (ARB, OP, ZK etc.) – 
                  the same way you would delegate to any other delegate. 
                  LobbyFi smart contracts only handle two things: coordinating voting power and distributing rewards.
                </p>
                <Info className="w-4 h-4 text-[#96fdbf] flex-shrink-0 mt-0.5" />
              </div>
            </div>
            <div className={`
              text-center mt-8 max-w-2xl mx-auto
              transform transition-all duration-700 ease-in-out delay-1000
              ${animate ? 'translate-y-0 opacity-100' : 'translate-y-4 opacity-0'}
            `}>
              <h2 className="text-4xl font-bold text-[#96fdbf] mb-6">Thus riskless APY for your voting power!</h2>
            </div>

            {/* Security Features */}
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mt-16 text-center">
              {[
                {
                  title: "Non-Custodial",
                  description: "Your tokens never leave your custody"
                },
                {
                  title: "Voting Rights Only",
                  description: "Delegate only voting power, keep full ownership"
                },
                {
                  title: "Full Control",
                  description: "Change or revoke delegation rights anytime"
                }
              ].map((feature, index) => (
                <div key={index} className={`
                  transform transition-all duration-700 ease-in-out delay-${(index + 3) * 200}
                  ${animate ? 'translate-y-0 opacity-100' : 'translate-y-4 opacity-0'}
                `}>
                <div className="flex items-start gap-4 p-6 
                    border-2 border-[#96fdbf]/30 rounded-lg 
                    bg-black/20 backdrop-blur-sm 
                    shadow-[0_0_15px_rgba(150,253,191,0.1)]
                    hover:shadow-[0_0_25px_rgba(150,253,191,0.3)]
                    hover:bg-black/40
                    transform transition-all duration-300 
                    h-32 w-full">
                    
                    <div className="flex flex-col justify-center h-full">
                      <h4 className="text-[#96fdbf] font-medium text-lg mb-1">{feature.title}</h4>
                      <p className="text-[#96fdbf]/70 text-sm">{feature.description}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TokenSafetySection;