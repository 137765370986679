import React, { useState, useEffect } from "react";
import {
  useAccount,
  useConnect,
  useSwitchChain,
  useWriteContract,
  useWaitForTransactionReceipt,
} from "wagmi";
import {
  ApolloProvider,
  ApolloClient,
  InMemoryCache,
  useQuery,
  gql,
  createHttpLink,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import Navbar from "./components/Navbar";
import InfoBar from "./components/InfoLine";
import VectorGrid from "./assets/vector_grid.svg";
import { FaXTwitter } from "react-icons/fa6";

// GraphQL query to check user status and get votes
const USER_STATUS_QUERY = gql`
  query UserStatus($wallet: String!) {
    yaperStatus(wallet: $wallet) {
      wallet_address
      validation_status
      total_votes
      total_yaps
      total_smart_followers
      available_rewards
      claimed_rewards
    }
    yapProposals(status: 0) {
      id
      name
      price_per_vote
      start_timestamp
      end_timestamp
      status
      validated_votes
      required_votes
    }
    yapVotes(wallet: $wallet) {
      id
      total_voted
      voted_yaps
      voted_smart_followers
      reward_amount
      vote_timestamp
      yap_proposal
    }
  }
`;

const httpLink = createHttpLink({
  uri: "https://lobbyfi.xyz/graphql", //http://localhost:4000/graphql https://lobbyfi.xyz/graphql
});

const authLink = setContext((_, { headers }) => {
  // Retrieve the API key from an environment variable or other secure storage
  const apiKey = process.env.REACT_APP_GRAPHQL_API_KEY;
  return {
    headers: {
      ...headers,
      "x-api-key": apiKey || "",
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

const YapsVotes = () => {
  const { address, isConnected, chainId: addressChainId } = useAccount();
  const { chainss, switchChain, switchChainAsync } = useSwitchChain();
  const [userState, setUserState] = useState("unregistered"); // unregistered, pending, validated
  const [errorMessage, setErrorMessage] = useState("");

  const { loading, error, data } = useQuery(USER_STATUS_QUERY, {
    variables: { wallet: address?.toLowerCase() },
    client,
    skip: !address,
  });

  const {
    writeContract: withdrawAllowance,
    data: withdrawData,
    isLoading: isWithdrawLoading,
    isSuccess: isWithdrawSuccess,
    error: withdrawError,
    status,
  } = useWriteContract({
    mutation: {
      onError: (error) => {
        let displayMessage = "Failed to claim rewards";
        console.error("Contract error:", error);
        if (error.message.includes("No allowance available")) {
          displayMessage = "No rewards available to claim";
          setErrorMessage(displayMessage);
        } else if (
          error.message.includes("reverted with the following reason:")
        ) {
          const reason = error.message
            .split("reverted with the following reason:")[1]
            .trim();
          setErrorMessage(reason);
        } else {
          setErrorMessage(error.message);
        }
      },
    },
  });
  const {
    data: withdrawReceipt,
    isLoading: isWaitingForWithdraw,
    isSuccess: isWithdrawConfirmed,
    error: waitForWithdrawError,
  } = useWaitForTransactionReceipt({
    hash: withdrawData?.hash,
  });
  const [withdrawTxHash, setWithdrawTxHash] = useState(null);

  const isRegistered = data?.yaperStatus?.validation_status > 0;
  const isValidated = data?.yaperStatus?.validation_status === 2;

  // Vote handler
  const handleVote = async (voteId) => {
    try {
      // Implementation for voting
      console.log("Voting on:", voteId);
    } catch (error) {
      console.error("Error voting:", error);
    }
  };

  const [showCopyMessage, setShowCopyMessage] = useState(false);

  const calculateTimeRemaining = (endTimestamp) => {
    // Convert milliseconds to seconds if needed
    const endTimeInSeconds =
      endTimestamp.toString().length === 13
        ? Math.floor(endTimestamp / 1000)
        : endTimestamp;

    const now = Math.floor(Date.now() / 1000); // Current time in seconds
    const timeLeft = endTimeInSeconds - now;

    if (timeLeft <= 0) return null;

    const days = Math.floor(timeLeft / (24 * 60 * 60));
    const hours = Math.floor((timeLeft % (24 * 60 * 60)) / (60 * 60));
    const minutes = Math.floor((timeLeft % (60 * 60)) / 60);

    return { days, hours, minutes };
  };
  const TimeRemaining = ({ endTimestamp }) => {
    const [timeRemaining, setTimeRemaining] = useState(
      calculateTimeRemaining(endTimestamp)
    );

    useEffect(() => {
      const timer = setInterval(() => {
        const remaining = calculateTimeRemaining(endTimestamp);
        setTimeRemaining(remaining);
      }, 60000); // Update every minute

      return () => clearInterval(timer);
    }, [endTimestamp]);

    if (!timeRemaining) return <p>Voting ended</p>;

    return (
      <p>
        Time remaining: {timeRemaining.days}d {timeRemaining.hours}h{" "}
        {timeRemaining.minutes}m
      </p>
    );
  };

  const handleClaimRewards = async (poolId) => {
    if (!isConnected) {
      const connector = connectors[0];
      if (connector) {
        try {
          await connect({ connector });
        } catch (error) {
          console.error("Connection request reset. Please try again:", error);
          return;
        }
      }
    }
    try {
      console.log("Attempting to withdraw rewards...");
      console.log("Pool ID:", poolId);

      withdrawAllowance({
        address: "0x992F1147D5aE5e61627Bc4C4DEB56AD647cF2644",
        abi: [
          {
            inputs: [
              { internalType: "string", name: "_poolName", type: "string" },
            ],
            name: "withdrawAllowance",
            outputs: [],
            stateMutability: "nonpayable",
            type: "function",
          },
        ],
        functionName: "withdrawAllowance",
        args: [poolId],
      });

      console.log("Write contract status:", status);
      console.log("Write contract error:", withdrawError);
    } catch (error) {
      console.error("Error in claim rewards:", error);
      console.error("Full error details:", {
        message: error.message,
        code: error.code,
        details: error,
      });
    }
  };

  useEffect(() => {
    if (isWithdrawSuccess && withdrawData) {
      setWithdrawTxHash(withdrawData.hash);
      console.log("Withdraw transaction sent:", withdrawData.hash);
    }
  }, [isWithdrawSuccess, withdrawData]);

  useEffect(() => {
    if (isWithdrawConfirmed && withdrawReceipt) {
      console.log("Withdraw confirmed:", withdrawReceipt);
    }
  }, [isWithdrawConfirmed, withdrawReceipt]);

  return (
    <div
      className="bg-black text-[#96fdbf] min-h-screen flex flex-col justify-between"
      style={{ fontFamily: "var(--body-text-font-family)" }}
    >
      <div className="flex flex-col items-center justify-between w-full">
        <Navbar />
        <InfoBar />
      </div>

      <div className="max-w-full">
        <div className="max-w-4xl mx-auto px-4">
          {!isConnected ? (
            // Not connected state
            <div className="bg-[#343434] rounded-lg shadow-lg p-5 mt-12">
              <p className="text-center text-lg">
                Please connect your wallet to see your votes
              </p>
            </div>
          ) : loading ? (
            // Loading state
            <div className="bg-[#343434] rounded-lg shadow-lg p-5 mt-12">
              <p className="text-center">Loading...</p>
            </div>
          ) : !isRegistered ? (
            <div className="bg-[#343434] rounded-lg shadow-lg p-8 mt-12">
              <h2 className="text-2xl text-center font-bold text-[#96fdbf] mb-8">
                Welcome, here you can sell your Yap votes anonymously!
              </h2>

              <div className="space-y-8 max-w-2xl mx-auto">
                {/* Steps Grid */}
                <div className="grid gap-6">
                  {/* Step 1 */}
                  <div className="bg-black/30 p-6 rounded-lg border border-[#96fdbf]/30">
                    <div className="flex items-start gap-4">
                      <div className="bg-[#96fdbf] text-black w-8 h-8 rounded-full flex items-center justify-center flex-shrink-0 font-bold">
                        1
                      </div>
                      <div>
                        <h3 className="text-lg font-bold text-[#96fdbf] mb-2">
                          DM Your ARB Wallet
                        </h3>
                        <p className="text-gray-300 mb-3">
                          Send your ARB wallet address to LobbyFi on Twitter.
                          This will be used to receive your rewards.
                        </p>
                        <div className="flex justify-center">
                          <a
                            href="https://x.com/lobbyfinance"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="flex items-center gap-2 px-4 py-2 bg-black border border-[#96fdbf] rounded-lg hover:bg-[#96fdbf]/10 transition-all"
                          >
                            <FaXTwitter size={16} />
                            <span>Message LobbyFi</span>
                          </a>
                        </div>
                        <p className="text-sm text-gray-400 mt-3">
                          Note: X OAuth integration coming soon for easier
                          registration
                        </p>
                      </div>
                    </div>
                  </div>

                  {/* Step 2 */}
                  <div className="bg-black/30 p-6 rounded-lg border border-[#96fdbf]/30">
                    <div className="flex items-start gap-4">
                      <div className="bg-[#96fdbf] text-black w-8 h-8 rounded-full flex items-center justify-center flex-shrink-0 font-bold">
                        2
                      </div>
                      <div>
                        <h3 className="text-lg font-bold text-[#96fdbf] mb-2">
                          Validation Period
                        </h3>
                        <p className="text-gray-300">
                          Our team will validate your account within a couple of
                          hours. You'll be able to see your status here once the
                          validation is complete.
                        </p>
                      </div>
                    </div>
                  </div>

                  {/* Step 3 */}
                  <div className="bg-black/30 p-6 rounded-lg border border-[#96fdbf]/30">
                    <div className="flex items-start gap-4">
                      <div className="bg-[#96fdbf] text-black w-8 h-8 rounded-full flex items-center justify-center flex-shrink-0 font-bold">
                        3
                      </div>
                      <div>
                        <h3 className="text-lg font-bold text-[#96fdbf] mb-2">
                          Start Earning
                        </h3>
                        <p className="text-gray-300">
                          Once validated, you can anonymously sell your Yaps and
                          earn rewards. Your can claim your earnings with your
                          registered ARB wallet.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Additional Info */}
                <div className="text-center text-sm text-gray-400 mt-6">
                  <div className="flex justify-center gap-4 mt-2">
                    <a
                      href="https://t.me/lobbyfichat"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="hover:text-[#96fdbf] transition-colors"
                    >
                      Need help?
                    </a>
                  </div>
                </div>
              </div>
            </div>
          ) : !isValidated ? (
            // Pending validation state
            <div className="bg-[#343434] rounded-lg shadow-lg p-8 mt-12">
              <div className="flex flex-col items-center">
                {/* Icon for pending state */}
                <div className="w-16 h-16 mb-4 rounded-full bg-black/30 border-2 border-[#96fdbf] flex items-center justify-center">
                  <svg
                    className="w-8 h-8 text-[#96fdbf] animate-spin"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    />
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    />
                  </svg>
                </div>

                <h2 className="text-2xl font-bold text-[#96fdbf] text-center mb-4">
                  Account Validation in Progress
                </h2>

                <div className="bg-black/30 p-6 rounded-lg max-w-md">
                  <p className="text-center text-gray-300 mb-4">
                    Your account is currently being validated. This process
                    typically takes a couple of hours.
                  </p>
                  <div className="text-sm text-gray-400 text-center">
                    <p>You'll be able to:</p>
                    <ul className="mt-2 space-y-1">
                      <li>• Sell your Yaps anonymously</li>
                      <li>• Earn rewards in USDC</li>
                      <li>• Track your voting history</li>
                    </ul>
                  </div>
                </div>

                <p className="mt-6 text-sm text-gray-400">
                  Feel free to join our{" "}
                  <a
                    href="https://t.me/lobbyfichat"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-[#96fdbf] hover:text-[#7fdba7] transition-colors"
                  >
                    Telegram
                  </a>{" "}
                  while you wait!
                </p>
              </div>
            </div>
          ) : (
            // Validated user state
            <>
              {/* Available Rewards Section */}
              <div className="bg-[#343434] rounded-lg shadow-lg p-5 mt-12">
                <div className="flex justify-around">
                  <div className="text-center">
                    <h3 className="text-lg mb-2">Available Rewards</h3>
                    <p className="text-2xl">
                      {(data?.yaperStatus?.available_rewards || 0) / 10 ** 6}{" "}
                      USDC
                    </p>
                  </div>
                  <div className="text-center">
                    <h3 className="text-lg mb-2">Claimed Rewards</h3>
                    <p className="text-2xl">
                      {(data?.yaperStatus?.claimed_rewards || 0) / 10 ** 6} USDC
                    </p>
                  </div>
                </div>
              </div>

              {/* Available Votes Section */}
              <div className="bg-[#343434] rounded-lg shadow-lg p-5 mt-12">
                <h2 className="text-xl text-center mb-4">Available Votes</h2>
                <div className="space-y-6">
                  {(data?.yapProposals.slice() || [])
                    .filter(
                      (proposal) =>
                        proposal.required_votes > 0 &&
                        proposal.price_per_vote > 0 &&
                        proposal.status === 0
                    )
                    .sort(
                      (a, b) =>
                        b.required_votes * b.price_per_vote -
                        a.required_votes * a.price_per_vote
                    )
                    .map((proposal) => {
                      const expectedReward =
                        ((data?.yaperStatus?.total_votes || 0) *
                          proposal.price_per_vote) /
                        10 ** 6;

                      return (
                        <div
                          key={proposal.id}
                          className="border-2 border-dashed border-[#96fdbf] p-6 rounded-lg hover:shadow-[0_0_15px_rgba(150,253,191,0.2)] transition-all duration-300"
                        >
                          {/* Header with Project Name and Time */}
                          <div className="flex justify-between items-center mb-4">
                            <h3 className="text-xl font-bold text-[#96fdbf]">
                              {proposal.name}
                            </h3>
                            <div className="text-right">
                              <TimeRemaining
                                endTimestamp={Number(proposal.end_timestamp)}
                              />
                              <p className="text-sm text-gray-400">
                                Ends:{" "}
                                {new Date(
                                  Number(proposal.end_timestamp)
                                ).toLocaleDateString("en-US", {
                                  year: "numeric",
                                  month: "long",
                                  day: "numeric",
                                })}
                              </p>
                            </div>
                          </div>

                          {/* Main Content Grid */}
                          <div className="grid grid-cols-2 gap-6 mb-4">
                            {/* Vote Stats */}
                            <div className="bg-black/30 p-4 rounded-lg">
                              <h4 className="text-sm text-gray-400 mb-2">
                                Voting Stats
                              </h4>
                              <div className="space-y-2">
                                <div className="flex flex-wrap justify-between">
                                  <span className="break-words">
                                    Your Voting Power:
                                  </span>
                                  <span className="text-[#96fdbf] font-bold break-all">
                                    {data?.yaperStatus?.total_votes || 0} Yaps
                                  </span>
                                </div>
                                <div className="flex flex-wrap justify-between">
                                  <span className="break-words">
                                    Required Votes:
                                  </span>
                                  <span className="text-[#96fdbf] font-bold break-all">
                                    {proposal.required_votes} Yaps
                                  </span>
                                </div>
                              </div>
                            </div>
                            {/* Rewards Info */}
                            <div className="bg-black/30 p-4 rounded-lg">
                              <div className="flex items-center gap-2 mb-2">
                                <h4 className="text-sm text-gray-400">
                                  Rewards
                                </h4>
                                <div className="relative group">
                                  <div className="cursor-help text-gray-400 hover:text-[#7fdba7]">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      className="h-4 w-4"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      stroke="currentColor"
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                      />
                                    </svg>
                                  </div>
                                  <div className="absolute left-0 bottom-full mb-2 hidden group-hover:block w-64 p-3 bg-black border border-[#96fdbf] rounded-lg text-sm text-[#96fdbf] shadow-lg z-10">
                                    Votes and rewards are validated every 12
                                    hours. It's normal if your votes and rewards
                                    are not visible immediately after voting.
                                  </div>
                                </div>
                              </div>
                              <div className="space-y-2">
                                <div className="flex flex-wrap justify-between gap-2">
                                  <span className="break-words">
                                    Price per Vote:
                                  </span>
                                  <span className="text-[#96fdbf] font-bold break-all">
                                    {(
                                      proposal.price_per_vote /
                                      10 ** 6
                                    ).toFixed(2)}{" "}
                                    USDC
                                  </span>
                                </div>
                                <div className="flex flex-wrap justify-between gap-2">
                                  <span className="break-words">
                                    Available Rewards:
                                  </span>
                                  <span className="text-[#96fdbf] font-bold break-all">
                                    {expectedReward.toFixed(2)} USDC
                                  </span>
                                  {/* Claim Rewards Button */}
                                  <button
                                    onClick={async () => {
                                      // Switch network if not on Arbitrum
                                      if (addressChainId !== 42161) {
                                        try {
                                          await switchChainAsync({
                                            chainId: 42161,
                                          });
                                        } catch (error) {
                                          console.error(
                                            "Failed to switch network:",
                                            error
                                          );
                                        }
                                        return;
                                      }
                                      handleClaimRewards(vote.yap_proposal);
                                    }}
                                    disabled={
                                      isWithdrawLoading ||
                                      isWaitingForWithdraw ||
                                      expectedReward <= 0
                                    }
                                    className={`w-full px-4 py-2 rounded-lg text-sm transition-all duration-300 mt-2 ${
                                      isWithdrawLoading ||
                                      isWaitingForWithdraw ||
                                      expectedReward <= 0
                                        ? "bg-gray-600 text-gray-300 cursor-not-allowed"
                                        : "bg-[#96fdbf] text-black hover:bg-[#7fdba7] hover:scale-105"
                                    }`}
                                  >
                                    {isWithdrawLoading
                                      ? "Claiming..."
                                      : isWaitingForWithdraw
                                      ? "Confirming..."
                                      : "Claim Rewards"}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* Action Button */}
                          <div className="w-full mt-4">
                            <div className="relative">
                              <button
                                onClick={() => {
                                  const url = `https://yaps.kaito.ai/connect/${proposal.id}?status=Active`;
                                  navigator.clipboard.writeText(url);
                                  setShowCopyMessage(proposal.id);
                                  setTimeout(
                                    () => setShowCopyMessage(null),
                                    2000
                                  );
                                }}
                                disabled={proposal.status !== 0}
                                className={`w-full px-8 py-3 rounded-lg transition-all duration-300 
                                            ${
                                              proposal.status === 0
                                                ? "bg-[#96fdbf] text-black hover:bg-[#7fdba7] hover:scale-105"
                                                : "bg-gray-600 text-gray-300 cursor-not-allowed"
                                            }`}
                              >
                                Copy Vote Link to Vote
                              </button>

                              {showCopyMessage === proposal.id && (
                                <div className="absolute right-0 mt-2 p-2 bg-black border border-[#96fdbf] rounded text-[#96fdbf] text-sm whitespace-nowrap">
                                  Link copied! Open in a new tab and vote.
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
              <div className="bg-[#343434] rounded-lg shadow-lg p-5 mt-12">
                <h2 className="text-xl text-center mb-4">Your Past Votes</h2>
                <div className="space-y-6">
                  {(data?.yapVotes || []).map((vote) => (
                    <div
                      key={vote.id}
                      className="border-2 border-dashed border-[#96fdbf] p-6 rounded-lg
                                    hover:shadow-[0_0_15px_rgba(150,253,191,0.2)] transition-all duration-300"
                    >
                      {/* Vote Header */}
                      <div className="flex justify-between items-center mb-4">
                        <div>
                          <h3 className="text-lg font-bold text-[#96fdbf]">
                            Vote Details
                          </h3>
                          <p className="text-sm text-gray-400">
                            {new Date(vote.vote_timestamp).toLocaleDateString(
                              "en-US",
                              {
                                year: "numeric",
                                month: "long",
                                day: "numeric",
                                hour: "2-digit",
                                minute: "2-digit",
                              }
                            )}
                          </p>
                        </div>
                        <div className="bg-black/30 px-4 py-2 rounded-lg flex items-center gap-4">
                          <div>
                            <span className="text-sm text-gray-400">
                              Reward
                            </span>
                            <p className="text-lg font-bold text-[#96fdbf] break-all">
                              {(vote.reward_amount / 10 ** 6).toFixed(2)} USDC
                            </p>
                          </div>
                          {/* Claim Rewards Button */}
                          <button
                            onClick={async () => {
                              // Switch network if not on Arbitrum
                              if (addressChainId !== 42161) {
                                try {
                                  await switchChainAsync({ chainId: 42161 });
                                } catch (error) {
                                  console.error(
                                    "Failed to switch network:",
                                    error
                                  );
                                }
                                return;
                              }

                              // If connected and on right network, proceed with claim
                              handleClaimRewards(vote.yap_proposal);
                            }}
                            disabled={
                              isWithdrawLoading ||
                              isWaitingForWithdraw ||
                              vote.reward_amount <= 0
                            }
                            className={`px-4 py-2 rounded-lg text-sm transition-all duration-300 ${
                              isWithdrawLoading ||
                              isWaitingForWithdraw ||
                              vote.reward_amount <= 0
                                ? "bg-gray-600 text-gray-300 cursor-not-allowed"
                                : "bg-[#96fdbf] text-black hover:bg-[#7fdba7] hover:scale-105"
                            }`}
                          >
                            {isWithdrawLoading
                              ? "Claiming..."
                              : isWaitingForWithdraw
                              ? "Confirming..."
                              : "Claim"}
                          </button>
                        </div>
                      </div>

                      {/* Vote Stats Grid */}
                      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                        <div className="bg-black/30 p-4 rounded-lg">
                          <h4 className="text-sm text-gray-400 mb-2">
                            Total Votes
                          </h4>
                          <p className="text-lg font-bold text-[#96fdbf] break-all">
                            {vote.total_voted}
                          </p>
                        </div>

                        <div className="bg-black/30 p-4 rounded-lg">
                          <h4 className="text-sm text-gray-400 mb-2">
                            Yaps Used
                          </h4>
                          <p className="text-lg font-bold text-[#96fdbf] break-all">
                            {vote.voted_yaps}
                          </p>
                        </div>

                        <div className="bg-black/30 p-4 rounded-lg">
                          <h4 className="text-sm text-gray-400 mb-2">
                            Smart Followers
                          </h4>
                          <p className="text-lg font-bold text-[#96fdbf] break-all">
                            {vote.voted_smart_followers}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                  {data.yapVotes.length === 0 && (
                    <div className="text-center text-gray-400 py-8">
                      No past votes found
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
        {errorMessage && (
          <div className="fixed bottom-8 left-1/2 transform -translate-x-1/2 z-50">
            <div className="bg-[#343434] px-6 py-3 rounded-lg shadow-lg flex items-center gap-2 border border-red-500">
              <svg
                className="w-5 h-5 text-red-500"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              <span className="text-[#96fdbf] text-sm">{errorMessage}</span>
            </div>
          </div>
        )}
      </div>

      {/* Footer */}
      <div className="flex flex-col items-end justify-center w-full mt-24">
        <div className="flex justify-between items-center w-full px-[2px] py-2 bg-black">
          <p className="text-xs sm:text-base font-body-text text-[#96fdbf] tracking-[1.00px] leading-[20px]">
            © 2025 LobbyFi. All rights reserved.
          </p>
        </div>
        <div className="w-full bg-black">
          <img src={VectorGrid} alt="Retro Vector Grid" className="w-full" />
        </div>
      </div>
    </div>
  );
};

export default YapsVotes;
