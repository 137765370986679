import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './Home';
import YapsLending from './Yaps_lending';
import Yaps from './Yaps_votes';
import YapsBuying from './Yaps_buying_index';
import YapsBuyingDetail from './Yaps_buying_show';
import MyRewards from './MyRewards';
import Proposals from './Proposals';
import ProposalDetail from './ProposalDetail';
import CurrentDelegationStatus from './CurrentDelegationStatus';


const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/"  element={<HomePage />} />
        <Route path="/yaps"  element={<YapsLending />} />
        <Route path="/TradeYaps"  element={<Yaps />} />
        <Route path="/BuyYaps"  element={<YapsBuying />} />
        <Route path="/MyRewards" element={<MyRewards />} />
        <Route path="/Proposals" element={<Proposals />} />
        <Route path="/proposals/:id" element={<ProposalDetail />} />
        <Route path="/BuyYaps/:id" element={<YapsBuyingDetail />} />
        <Route path="/CurrentDelegationStatus" element={<CurrentDelegationStatus />} />
        <Route path="*" element={<div>Page not found</div>} />
      </Routes>
    </Router>
  );
};

export default App;