import React, { useState } from 'react';
import { User, Building2, DollarSign, XCircle, CheckCircle2, ArrowRight, ArrowLeft, Users } from 'lucide-react';
import LobbyFiLogo from '../assets/LobbyFi.png';

const Card = ({ icon: Icon, img, title, description, benefits = [], isHighlighted = false }) => (
    <div className={`
      relative border-2 rounded-lg p-6 transition-all duration-300
      ${isHighlighted 
        ? 'border-[#96fdbf] bg-black/40 shadow-[0_0_25px_rgba(150,253,191,0.3)] border-opacity-100' 
        : 'border-[#96fdbf]/30 bg-black/20'}
      hover:shadow-[0_0_30px_rgba(150,253,191,0.4)]
      hover:bg-black/60 w-full max-w-sm mx-auto
      hover:border-opacity-100
      backdrop-blur-sm
    `}>
      <div className={`flex flex-col items-center text-center ${img ? 'gap-2' : 'gap-4'}`}>
        {img ? (
          <img 
            src={img} 
            alt={title} 
            className="w-32 h-32 object-contain"
          />
        ) : (
          <Icon className={`w-12 h-12 ${isHighlighted ? 'text-[#96fdbf]' : 'text-[#96fdbf]/60'}`} />
        )}
        <h3 className={`text-xl font-bold ${isHighlighted ? 'text-[#96fdbf]' : 'text-[#96fdbf]/90'}`}>
          {title}
        </h3>
        {description && (
          <p className="text-[#96fdbf]/70 text-sm">
            {description}
          </p>
        )}
        {benefits.length > 0 && (
          <ul className={`${description ? 'mt-4' : 'mt-2'} space-y-3 w-full`}>
            {benefits.map((benefit, index) => (
              <li key={index} className="flex items-center gap-3 text-sm">
                {benefit.enabled ? (
                  <CheckCircle2 className="w-5 h-5 flex-shrink-0 text-[#96fdbf]" />
                ) : (
                  <XCircle className="w-5 h-5 flex-shrink-0 text-[#96fdbf]/30" />
                )}
                <span className={benefit.enabled ? 'text-[#96fdbf]' : 'text-[#96fdbf]/50'}>
                  {benefit.text}
                </span>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
);

const DelegationFlow = () => {
  const [activeView, setActiveView] = useState('traditional');

  const noBenefits = [
    { text: "Tokens in self-custody", enabled: true },
    { text: "Participate in governance", enabled: false },
    { text: "Outsource participation", enabled: false },
    { text: "Earn delegation rewards", enabled: false }
  ];

  const selfDelegationBenefits = [
    { text: "Tokens in self-custody", enabled: true },
    { text: "Participate in governance", enabled: true },
    { text: "Outsource participation", enabled: false },
    { text: "Earn delegation rewards", enabled: false }
  ];

  const commonBenefits = [
    { text: "Tokens in self-custody", enabled: true },
    { text: "Participate in governance", enabled: true },
    { text: "Outsource participation", enabled: true },
    { text: "Earn delegation rewards", enabled: false }
  ];

  const lobbyFiBenefits = [
    { text: "Tokens in self-custody", enabled: true },
    { text: "Participate in governance", enabled: true },
    { text: "Outsource participation", enabled: true },
    { text: "Earn delegation rewards", enabled: true }
  ];

  const RewardAnimation = () => {
    const [isAnimating, setIsAnimating] = useState(true);
  
    useEffect(() => {
      const interval = setInterval(() => {
        setIsAnimating(prev => !prev);
        // Reset animation after a brief pause
        setTimeout(() => setIsAnimating(true), 100);
      }, 3000); // Repeat every 3 seconds
  
      return () => clearInterval(interval);
    }, []);
  
    return (
      <div className="relative">
        <div className={`
          absolute 
          md:left-0 md:top-1/2 md:-translate-y-1/2
          left-1/2 top-0 -translate-x-1/2 md:translate-x-0
          transition-all duration-1000 ease-in-out
          ${isAnimating 
            ? 'md:translate-x-[-200px] translate-y-[-100px] opacity-0' 
            : 'md:translate-x-0 translate-y-0 opacity-100'}
        `}>
          <EthCoin />
        </div>
      </div>
    );
  };

  return (
    <div className="w-full py-0 px-4 bg-transparent" style={{ fontFamily: "var(--body-text-font-family)" }}>
      <div className="max-w-6xl mx-auto">
        {/* View Toggle */}
        <div className="flex justify-center mb-16">
          <div className="flex flex-col sm:flex-row border-2 border-[#96fdbf] rounded-lg overflow-hidden 
                        shadow-[0_0_15px_rgba(150,253,191,0.15)]">
            <button
              onClick={() => setActiveView('traditional')}
              className={`px-8 py-3 transition-all duration-300 text-sm font-medium ${
                activeView === 'traditional' 
                  ? 'bg-[#96fdbf] text-black' 
                  : 'bg-transparent text-[#96fdbf] hover:bg-[#3DFF54]/10 hover:text-[#3DFF54]'
              }`}
            >
              Traditional Delegation
            </button>
            <button
              onClick={() => setActiveView('lobbyfi')}
              className={`px-8 py-3 transition-all duration-300 text-sm font-medium ${
                activeView === 'lobbyfi' 
                  ? 'bg-[#96fdbf] text-black' 
                  : 'bg-transparent text-[#96fdbf] hover:bg-[#3DFF54]/10 hover:text-[#3DFF54]'
              }`}
            >
              LobbyFi Delegation
            </button>
          </div>
        </div>

        {activeView === 'traditional' ? (
          <div className="space-y-12">
            <div className="text-center space-y-4 mb-12">
              <h2 className="text-3xl font-bold text-[#96fdbf]">Traditional Delegation</h2>
              <p className="text-[#96fdbf]/70 max-w-2xl mx-auto">
                Current governance delegation options for token holders:
              </p>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              <Card
                icon={XCircle}
                title="No Delegation"

                description="Keep tokens idle without participating in governance"
                benefits={noBenefits}
              />
              <Card
                icon={User}
                title="Self Delegation"
                description="Activate your voting power to vote on your own"
                benefits={selfDelegationBenefits}
              />
              <Card
                icon={Users}
                title="Delegate to Others"
                description="Transfer voting power to another address"
                benefits={commonBenefits}
              />
            </div>
          </div>
        ) : (
          <div className="space-y-12">
            <div className="text-center space-y-4 mb-12">
              <h2 className="text-3xl font-bold text-[#96fdbf]">Delegation to LobbyFi</h2>
              <p className="text-[#96fdbf]/70 max-w-2xl mx-auto">
                Earn rewards for your delegation, the governance power matters!
              </p>
            </div>
            <div className="flex flex-col items-center gap-8">
            <div className="flex flex-col md:flex-row items-center gap-8 w-full justify-center">
  <Card
    icon={User}
    title="Token Holder"
    description="Start by delegating to LobbyFi"
    isHighlighted
  />
  <div className="flex flex-row md:flex-col items-center justify-center gap-4">
    <div className="flex flex-col items-center">
      <span className="text-sm text-[#96fdbf]">Votes</span>
      <ArrowRight className="w-12 h-12 text-[#96fdbf] rotate-90 md:rotate-0" />
    </div>
    <div className="flex flex-col items-center">
      <ArrowLeft className="w-12 h-12 text-[#96fdbf] rotate-90 md:rotate-0" />
      <span className="text-sm text-[#96fdbf]">ETH</span>
    </div>
  </div>
  <Card
    img={LobbyFiLogo}
    benefits={lobbyFiBenefits}
    isHighlighted
  />
</div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DelegationFlow;