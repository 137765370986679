import React, { useEffect, useState, useRef } from "react";
import { Connector, useConnect, useAccount, useDisconnect, useEnsAvatar, useEnsName } from 'wagmi';
import { config } from '../config';
import { getChainId, switchChain } from '@wagmi/core'
import ArbLogo from '../assets/arb-logo.png';
import ZksyncLogo from '../assets/zkSyncZlogo.png';
import OpLogo from '../assets/op-logo.png';
import MantaLogo from '../assets/mantaLogo.png';
import GnoLogo from '../assets/gnosis-logo.png';
import BlastLogo from '../assets/blastLogo.png';
import ScrollLogo from '../assets/scroll-logo.png';


const WalletConnectButton = () => {
  const [rendered, setRendered] = useState("");
  const [showMenu, setShowMenu] = useState(false);
  const { connectors, connect } = useConnect();
  const [error, setError] = useState(null);
  const dropdownRef = useRef(null);

  const { address } = useAccount();
  const { data: ensName } = useEnsName({ address });
  const chainId = getChainId(config);
  const { disconnect } = useDisconnect();

  // Chain configuration with names and IDs
  const chains = [
    { id: 42161, name: 'Arbitrum', logo: ArbLogo },
    { id: 81457, name: 'Blast', logo: BlastLogo },
    { id: 169, name: 'Manta Pacific', logo: MantaLogo },
    { id: 10, name: 'Optimism', logo: OpLogo },
    { id: 534352, name: 'Scroll', logo: ScrollLogo },
    { id: 324, name: 'zkSync', logo: ZksyncLogo },
  ];

  const isChainSupported = chainId && chains.some(chain => chain.id === chainId);
  const currentChain = chains.find(chain => chain.id === chainId);

  const shortenAddress = (address) => {
    return `${address.slice(0, 6)}...${address.slice(-4)}`;
  };

  useEffect(() => {
    if (ensName) {
      setRendered(ensName);
    } else if (address) {
      setRendered(shortenAddress(address));
    } else {
      setRendered("");
    }
  }, [address, ensName]);

  const handleButtonClick = async () => {
    if (!address) {
      const connector = connectors[1]; // WalletConnect connector
      if (connector) {
        try {
          await connect({ connector });
        } catch (error) {
          console.error('Connection error:', error);
          setError('Failed to connect. Please try again.');
        }
      }
    } else {
      setShowMenu(!showMenu);
    }
  };

  const handleNetworkSwitch = async (newChainId) => {
    if (chainId !== newChainId) {
      try {
        await switchChain(config, { chainId: newChainId });
        setError(null);
        setShowMenu(false);
      } catch (err) {
        console.error("Network switch error:", err);
        setError(err.message);
      }
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowMenu(false);
      }
    };
  
    window.addEventListener('click', handleClickOutside);
    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <div className="relative w-full">
      {/* Main Button */}
      <button
        onClick={handleButtonClick}
        className="w-full px-5 py-2 font-header text-[#96fdbf] hover:text-[#3DFF54] transition-colors rounded-sm bg-black"
        style={{ fontFamily: "var(--body-text-font-family)" }}
      >
        <div className="flex items-center justify-center w-full bg-[#373737] hover:bg-gray-800 px-4 py-2.5 rounded transition-all">
          {rendered !== "" && isChainSupported && currentChain && (
            <img 
              src={currentChain.logo} 
              alt="Chain Logo" 
              className="w-5 h-5" 
            />
          )}
          <span className="truncate text-lg">
            {rendered === "" ? "[connect wallet]" : rendered}
          </span>
        </div>
      </button>

      {/* Mobile Dropdown Menu */}
      {showMenu && address && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50">
          <div className="fixed top-0 left-0 right-0 bg-black border-t border-[#96fdbf] rounded-t-lg shadow-lg">
            {/* Title */}
            <div className="flex justify-center px-4 py-3 border-b border-[#373737]">
              <span className="text-[#96fdbf] text-lg font-header" style={{ fontFamily: "var(--body-text-font-family)" }}>
                Select Network
              </span>
            </div>

            {/* Network List */}
            <div className="max-h-[70vh] overflow-y-auto py-2">
              {chains.map((chain) => {
                if (chain.id !== chainId) {
                  return (
                    <button 
                      key={chain.id}
                      onClick={() => handleNetworkSwitch(chain.id)}
                      className="flex items-center w-full px-4 py-3 text-[#96fdbf] hover:text-[#3DFF54] transition-all group"
                      style={{ fontFamily: "var(--body-text-font-family)" }}
                    >
                      <div className="flex items-center w-full bg-[#373737] hover:bg-gray-800 px-4 py-2.5 rounded transition-all">
                        <img src={chain.logo} alt={`${chain.name} Logo`} className="w-6 h-6 mr-3" />
                        <span className="text-base">Switch to {chain.name}</span>
                      </div>
                    </button>
                  );
                }
                return null;
              })}
            </div>
            
            {/* Divider */}
            <div className="h-px bg-[#373737]"></div>
            
            {/* Disconnect Button */}
            <div className="p-2">
              <button 
                onClick={() => {
                  disconnect();
                  setShowMenu(false);
                }}
                className="flex items-center w-full px-4 py-3 text-[#96fdbf] hover:text-[#3DFF54] transition-all group"
                style={{ fontFamily: "var(--body-text-font-family)" }}
              >
                <div className="flex justify-center items-center w-full bg-[#F02323]/50 hover:bg-gray-800 px-4 py-2.5 rounded transition-all">
                  <span className="text-base">Disconnect</span>
                </div>
              </button>
            </div>

            {/* Cancel Button for Mobile */}
            <div className="p-2 pb-4">
              <button 
                onClick={() => setShowMenu(false)}
                className="flex items-center w-full px-4 py-3 text-[#96fdbf] hover:text-[#3DFF54] transition-all group"
                style={{ fontFamily: "var(--body-text-font-family)" }}
              >
                <div className="flex items-center justify-center w-full bg-[#373737] hover:bg-gray-800 px-4 py-2.5 rounded transition-all">
                  <span className="text-base">Cancel</span>
                </div>
              </button>
            </div>

            {/* Error Message */}
            {error && (
              <div className="px-4 py-2 text-red-400 text-sm border-t border-[#373737]">
                {error}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default WalletConnectButton;