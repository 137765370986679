import { React, useEffect, useState } from "react";
import { useEthers } from "@usedapp/core";

import LobbyFiLogo from "./assets/LobbyFiLogo.png";
import LobbyFiBuilding from "./assets/building.png";
import twitter from "./assets/twitter.svg";
import telegram from "./assets/telegram.svg";
import VectorGrid from "./assets/vector_grid.svg";
import WalletButton from "./components/WalletButton";
import MatrixRain from "./components/MatrixRain";
import TypingTextComponent from "./components/TypingText";
import Navbar from "./components/Navbar";
import InfoBar from "./components/InfoLine";
import DelegationExplainer from "./components/DelegationExplainer";
import TokenSafetySection from "./components/TokenSafetySection";
import SnapshotLogo from "./assets/snapshotlogo.png";
import ArbLogo from "./assets/arb-logo.png";
import ZksyncLogo from "./assets/zkSync_white-logo.png";
import OpLogo from "./assets/op-logo.png";
import MantaLogo from "./assets/mantaLogo.png";
import ScrollLogo from "./assets/scroll-logo.png";
import GnoLogo from "./assets/gnosis-logo.png";
import BlastLogo from "./assets/blastLogo.png";
import "./tailwind.css";
import MyRewards from "./MyRewards";
import { useNavigate } from "react-router-dom";
import { setContext } from "@apollo/client/link/context";
import { FaTelegram } from "react-icons/fa";
import {
  ApolloProvider,
  ApolloClient,
  InMemoryCache,
  useQuery,
  gql,
  createHttpLink,
} from "@apollo/client";

const httpLink = createHttpLink({
  uri: "https://lobbyfi.xyz/graphql", //http://localhost:4000/graphqlhttps://lobbyfi.xyz/graphql
});

const authLink = setContext((_, { headers }) => {
  // Retrieve the API key from an environment variable or other secure storage
  const apiKey = process.env.REACT_APP_GRAPHQL_API_KEY;
  return {
    headers: {
      ...headers,
      "x-api-key": apiKey || "",
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

const VOTING_POWER_QUERY = gql`
  query DelegationSums {
    delegationSums {
      network
      erc20
      sum
    }
  }
`;

window.addEventListener(
  "error",
  function (e) {
    // Prevent any error from being reported
    e.stopImmediatePropagation();
    e.preventDefault();
    return true;
  },
  true
);

// Override the global error handler
window.onerror = function () {
  return true;
};

// Disable React's error reporting system
if (typeof window !== "undefined") {
  window.__REACT_ERROR_OVERLAY__ = null;
}

const Home = () => {
  const { activateBrowserWallet, account } = useEthers();
  const [daos, setDaos] = useState([]);

  const { loading, error, data } = useQuery(VOTING_POWER_QUERY, {
    client,
  });

  useEffect(() => {
    if (data && data.delegationSums) {
      setDaos([
        {
          name: "Arbitrum DAO",
          logo: ArbLogo,
          votingPower: data.delegationSums.find((dao) => dao.network === 42161),
        },
        {
          name: "Blast DAO",
          logo: BlastLogo,
          votingPower: data.delegationSums.find((dao) => dao.network === 81457),
        },
        {
          name: "ZkSync DAO",
          logo: ZksyncLogo,
          votingPower: data.delegationSums.find((dao) => dao.network === 324),
        },
        {
          name: "Optimism DAO",
          logo: OpLogo,
          votingPower: data.delegationSums.find((dao) => dao.network === 10),
        },
        {
          name: "Manta DAO",
          logo: MantaLogo,
          votingPower: data.delegationSums.find((dao) => dao.network === 169),
        },
        {
          name: "Scroll DAO",
          logo: ScrollLogo,
          votingPower: data.delegationSums.find(
            (dao) => dao.network === 534352
          ),
        },
      ]);
    }
  }, [data]);

  const handleClick = () => {
    alert("Coming soon. Use our telegram group for now."); // Display coming soon message
  };

  const navigate = useNavigate();

  const handleSellClick = () => {
    navigate("/TradeYaps");
  };

  const handleBuyClick = () => {
    navigate("/BuyYaps");
  };

  return (
    <div className="relative bg-black min-h-screen">
      <MatrixRain />
      <div className="flex flex-col  items-center justify-between w-full relative z-10">
        <Navbar />
        <InfoBar />
        <div className="flex flex-col items-center w-full max-w-[1080px]">
          {/* Image of LobbyFiBuilding */}
          <div className="md:w-[504px] w-full">
            <img
              className="hidden md:block w-full h-[294px] object-cover"
              alt="Image of LobbyFiBuilding"
              src={LobbyFiBuilding}
            />
          </div>

          <div className="flex flex-col items-center space-y-4 md:space-y-0 md:flex-row md:justify-between mt-4 w-full md:w-[720px]">
            <div
              style={{
                position: "relative",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "200px",
                height: "100px",
              }}
            >
              <div className="relative flex items-center justify-center w-[200px] h-[100px]">
                <button
                  onClick={handleSellClick}
                  className="flex items-center justify-center w-[200px] h-[50px] 
                            rounded-full bg-red-600 text-white text-lg cursor-pointer
                            border-2 border-red-400/70
                            shadow-[0_0_20px_rgba(239,68,68,0.3)]
                            hover:shadow-[0_0_30px_rgba(239,68,68,0.5)]
                            hover:scale-105
                            hover:border-red-400
                            transform transition-all duration-300
                            backdrop-blur-sm"
                  style={{ fontFamily: "var(--body-text-font-family)" }}
                >
                  [sell yaps]
                </button>
              </div>
            </div>

            <div
              style={{
                position: "relative",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "200px",
                height: "100px",
              }}
            >
              <div className="relative flex items-center justify-center w-[200px] h-[100px]">
                <button
                  onClick={handleBuyClick}
                  className="flex items-center justify-center w-[200px] h-[50px] 
                            rounded-full bg-blue-600 text-white text-lg cursor-pointer
                            border-2 border-blue-400
                            shadow-[0_0_20px_rgba(59,130,246,0.3)]
                            hover:shadow-[0_0_30px_rgba(59,130,246,0.5)]
                            hover:scale-105
                            transform transition-all duration-300
                            backdrop-blur-sm"
                  style={{ fontFamily: "var(--body-text-font-family)" }}
                >
                  [buy yaps]
                </button>
              </div>
            </div>
          </div>
          <div className="px-2 sm:px-0 max-w-[720px] mx-auto">
            <div className="text-center mt-10">
              <div
                className="text-sm sm:text-4xl font-bold text-[#96fdbf]"
                style={{ fontFamily: "var(--body-text-font-family)" }}
              >
                <div className="mx-auto">
                  The trading of the Yap votes is live!
                  <br />
                </div>
              </div>
            </div>
          </div>
          <div
            style={{ position: "relative" }}
            className="flex items-center justify-center w-[180px] sm:w-[280px] md:w-[300px] h-[100px]"
          >
            <div className="relative flex items-center justify-center w-full h-[100px]">
              <a
                href="https://t.me/lobbyfichat"
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center justify-center w-full h-[50px] 
                    rounded-full bg-green-600 text-white text-base sm:text-lg cursor-pointer
                    border-2 border-green-400
                    shadow-[0_0_20px_rgba(150,253,191,0.3)]
                    hover:shadow-[0_0_30px_rgba(150,253,191,0.5)]
                    hover:scale-105
                    transform transition-all duration-300
                    backdrop-blur-sm"
                style={{ fontFamily: "var(--body-text-font-family)" }}
              >
                <FaTelegram className="mr-2" size={20} />
                [join telegram]
              </a>
            </div>
          </div>
        </div>
        {/* Footer */}
        <div className="flex flex-col items-end justify-center w-full mt-16">
          <div className="flex justify-between items-center w-full px-[2px] py-2 bg-black">
            {/* Text */}
            <p
              className="text-xs sm:text-base font-body-text text-[#96fdbf] tracking-[1.00px] leading-[20px]"
              style={{ fontFamily: "var(--body-text-font-family)" }}
            >
              © 2025 LobbyFi. All rights reserved.
            </p>
          </div>

          {/* Grid Component */}
          <div className="w-full  bg-black">
            <img src={VectorGrid} alt="Retro Vector Grid" className="w-full" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
