import React, { useEffect, useState, useRef } from "react";
import { Connector, useConnect, useAccount, useDisconnect, useEnsAvatar, useEnsName } from 'wagmi';
import { config } from '../config';
import { getChainId, switchChain } from '@wagmi/core'
import ArbLogo from '../assets/arb-logo.png';
import ZksyncLogo from '../assets/zkSyncZlogo.png';
import OpLogo from '../assets/op-logo.png';
import MantaLogo from '../assets/mantaLogo.png';
import GnoLogo from '../assets/gnosis-logo.png';
import BlastLogo from '../assets/blastLogo.png';
import ScrollLogo from '../assets/scroll-logo.png';


const WalletButton = () => {
  const [rendered, setRendered] = useState("");
  const [showMenu, setShowMenu] = useState(false);
  const { connectors, connect } = useConnect();
  const dropdownRef = useRef(null);
  const [error, setError] = useState(null);

  const { address } = useAccount();
  const { data: ensName } = useEnsName({ address });
  const chainId = getChainId(config);
  const { disconnect } = useDisconnect();

  const chainIdToLogo = {
    324: ZksyncLogo,
    42161: ArbLogo,
    10: OpLogo,
    169: MantaLogo,
    81457: BlastLogo, 
    534352: ScrollLogo
  };

  const chains = [
    { id: 42161, name: 'Arbitrum', logo: ArbLogo },
    { id: 81457, name: 'Blast', logo: BlastLogo },
    { id: 169, name: 'Manta Pacific', logo: MantaLogo },
    { id: 10, name: 'Optimism', logo: OpLogo },
    { id: 534352, name: 'Scroll', logo: ScrollLogo },
    { id: 324, name: 'zkSync', logo: ZksyncLogo },
  ];

  const isChainSupported = chainId && chains.some(chain => chain.id === chainId);
  const currentChain = chains.find(chain => chain.id === chainId);

  const shortenAddress = (address) => {
    return `${address.slice(0, 6)}...${address.slice(-4)}`;
  };

  useEffect(() => {
    if (ensName) {
      setRendered(ensName);
    } else if (address) {
      setRendered(shortenAddress(address));
    } else {
      setRendered("");
    }
  }, [address, ensName]);

  const handleButtonClick = async (event) => {
    event.stopPropagation();
    if (!address) {
      const connector = connectors[0];
      if (connector) {
        try {
          await connect({ connector });
        } catch (error) {
          console.error('Connection error:', error);
        }
      }
    } else {
      setShowMenu(!showMenu);
    }
  };

  const handleNetworkSwitch = async (newChainId) => {
    if (chainId !== newChainId) {
      try {
        await switchChain(config, { chainId: newChainId });
        setError(null);
      } catch (err) {
        console.error("Network switch error:", err);
        setError(err.message);
      }
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowMenu(false);
      }
    };
  
    window.addEventListener('click', handleClickOutside);
    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <div className="relative inline-block">
      {/* Main Button */}
      <button
        onClick={handleButtonClick}
        className="min-w-[180px] px-5 py-2 font-header text-[#96fdbf] hover:text-[#3DFF54] transition-colors rounded-sm bg-black"
        style={{ fontFamily: "var(--body-text-font-family)" }}
      >
        <div className="flex items-center justify-start space-x-3">
          {rendered !== "" && isChainSupported && currentChain && (
              <img 
                src={currentChain.logo} 
                alt="Chain Logo" 
                className="w-5 h-5" 
              />
            )}
          <span className="truncate text-lg">
            {rendered === "" ? "[connect wallet]" : rendered}
          </span>
        </div>
      </button>

      {/* Dropdown Menu */}
      {showMenu && address && (
        <div 
          ref={dropdownRef}
          className="absolute right-0 mt-3 w-96 bg-black border border-[#96fdbf] rounded-sm shadow-lg shadow-[#96fdbf]/10 z-50 backdrop-blur-sm"
        >
          {/* Title */}
          <div className="flex justify-center px-4 py-3 border-b border-[#373737]">
            <span className="text-[#96fdbf] text-lg font-header" style={{ fontFamily: "var(--body-text-font-family)" }}>Select Network</span>
          </div>

          {/* Network List */}
          <div className="py-2">
            {chains.map((chain) => {
              if (chain.id !== chainId) {
                return (
                  <button 
                    key={chain.id}
                    onClick={() => handleNetworkSwitch(chain.id)}
                    className="flex items-center w-full px-4 py-3 text-[#96fdbf] hover:text-[#3DFF54] transition-all group"
                    style={{ fontFamily: "var(--body-text-font-family)" }}
                  >
                    <div className="flex items-center w-full bg-[#373737] hover:bg-gray-800 px-4 py-2.5 rounded transition-all">
                      <img src={chain.logo} alt={`${chain.name} Logo`} className="w-6 h-6 mr-3" />
                      <span className="text-base">Switch to {chain.name}</span>
                    </div>
                  </button>
                );
              }
              return null;
            })}
          </div>
          
          {/* Divider */}
          <div className="h-px bg-[#96fdbf]/20"></div>
          
          {/* Disconnect Button */}
          <div className="p-2">
            <button 
              onClick={disconnect}
              className="flex items-center w-full px-4 py-3 text-[#96fdbf] hover:text-[#3DFF54] transition-all group"
              style={{ fontFamily: "var(--body-text-font-family)" }}
            >
              <div className="flex justify-center items-center w-full bg-[#F02323]/50 hover:bg-gray-800 px-4 py-2.5 rounded transition-all">
                <span className="text-base">Disconnect</span>
              </div>
            </button>
          </div>

          {/* Error Message */}
          {error && (
            <div className="px-4 py-2 text-red-400 text-sm border-t border-[#96fdbf]/20">
              {error}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default WalletButton;