import React, { useMemo, useState, useEffect } from "react";
import { useAccount } from "wagmi";
import {
  ApolloProvider,
  ApolloClient,
  InMemoryCache,
  useQuery,
  gql,
  createHttpLink,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import Navbar from "./components/Navbar";
import InfoBar from "./components/InfoLine";
import VectorGrid from "./assets/vector_grid.svg";

const YAP_PROPOSALS_QUERY = gql`
  query YapProposals {
    yapProposals(status: 0) {
      id
      name
      price_per_vote
      start_timestamp
      end_timestamp
      status
      validated_votes
      required_votes
    }
  }
`;

const httpLink = createHttpLink({
  uri: "https://lobbyfi.xyz/graphql", //http://localhost:4000/graphql https://lobbyfi.xyz/graphql
});

const authLink = setContext((_, { headers }) => {
  // Retrieve the API key from an environment variable or other secure storage
  const apiKey = process.env.REACT_APP_GRAPHQL_API_KEY;
  return {
    headers: {
      ...headers,
      "x-api-key": apiKey || "",
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

const TimeRemaining = ({ endTimestamp }) => {
  const [timeRemaining, setTimeRemaining] = useState(
    calculateTimeRemaining(endTimestamp)
  );

  useEffect(() => {
    const timer = setInterval(() => {
      const remaining = calculateTimeRemaining(endTimestamp);
      setTimeRemaining(remaining);
    }, 60000); // Update every minute

    return () => clearInterval(timer);
  }, [endTimestamp]);

  if (!timeRemaining) return <p>Voting ended</p>;

  return (
    <p>
      Time remaining: {timeRemaining.days}d {timeRemaining.hours}h{" "}
      {timeRemaining.minutes}m
    </p>
  );
};

const calculateTimeRemaining = (endTimestamp) => {
  // Convert milliseconds to seconds if needed
  const endTimeInSeconds =
    endTimestamp.toString().length === 13
      ? Math.floor(endTimestamp / 1000)
      : endTimestamp;

  const now = Math.floor(Date.now() / 1000); // Current time in seconds
  const timeLeft = endTimeInSeconds - now;

  if (timeLeft <= 0) return null;

  const days = Math.floor(timeLeft / (24 * 60 * 60));
  const hours = Math.floor((timeLeft % (24 * 60 * 60)) / (60 * 60));
  const minutes = Math.floor((timeLeft % (60 * 60)) / 60);

  return { days, hours, minutes };
};

const YapProposalCard = ({ proposal }) => {
  const totalValue =
    (Number(proposal.required_votes) * Number(proposal.price_per_vote)) /
    10 ** 6;

  return (
    <div
      className="border-2 border-dashed border-[#96fdbf] p-6 rounded-lg hover:shadow-[0_0_15px_rgba(150,253,191,0.2)] transition-all duration-300"
      style={{ fontFamily: "var(--body-text-font-family)" }}
    >
      <div className="flex justify-between items-start mb-4">
        <h3 className="text-xl font-bold text-[#96fdbf]">{proposal.name}</h3>
        <TimeRemaining endTimestamp={Number(proposal.end_timestamp)} />
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-4">
        {/* Vote Requirements */}
        <div className="bg-black/30 p-4 rounded-lg">
          <h4 className="text-sm text-gray-400 mb-2">Required Votes</h4>
          <div className="space-y-2">
            <div className="flex justify-between">
              <span>Total Required:</span>
              <span className="text-[#96fdbf] font-bold">
                {proposal.required_votes} Yaps
              </span>
            </div>
            <div className="flex justify-between">
              <span>Already Validated:</span>
              <span className="text-[#96fdbf] font-bold">
                {proposal.validated_votes} Yaps
              </span>
            </div>
          </div>
        </div>

        {/* Price Info */}
        <div className="bg-black/30 p-4 rounded-lg">
          <h4 className="text-sm text-gray-400 mb-2">Price Details</h4>
          <div className="space-y-2">
            <div className="flex justify-between">
              <span>Price per Vote:</span>
              <span className="text-[#96fdbf] font-bold">
                {Number(proposal.price_per_vote) / 10 ** 6} USDC
              </span>
            </div>
            <div className="flex justify-between">
              <span>Total Budget:</span>
              <span className="text-[#96fdbf] font-bold">
                {totalValue.toFixed(2)} USDC
              </span>
            </div>
          </div>
        </div>
      </div>

      {/* Action Button */}
      <div className="flex justify-end mt-4">
        <button
          onClick={() => (window.location.href = `/BuyYaps/${proposal.id}`)}
          className="w-full px-8 py-3 rounded-lg bg-[#96fdbf] text-black hover:bg-[#7fdba7] 
                             hover:scale-105 transition-all duration-300"
        >
          Add Budget
        </button>
      </div>
    </div>
  );
};

const YapProposalGroup = ({ title, proposals }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const endDate = new Date(Number(proposals[0].end_timestamp));

  const visibleProposals = isExpanded ? proposals : proposals.slice(0, 3);
  const hasMoreProposals = proposals.length > 3;

  return (
    <div className="mb-12">
      <div className="flex items-center mb-6">
        <div>
          <h2 className="text-2xl font-bold text-[#96fdbf]">
            Voting period ends{" "}
            {endDate.toLocaleDateString("en-DE", {
              weekday: "long",
              month: "long",
              day: "numeric",
              year: "numeric",
              hour: "2-digit",
              minute: "2-digit",
            })}
          </h2>
        </div>
        <div className="ml-4 h-px flex-grow bg-[#96fdbf]/30"></div>
      </div>
      <div className="space-y-6">
        {visibleProposals.map((proposal) => (
          <div key={proposal.id} className="bg-[#343434] rounded-lg shadow-lg">
            <YapProposalCard proposal={proposal} />
          </div>
        ))}

        {hasMoreProposals && (
          <button
            onClick={() => setIsExpanded(!isExpanded)}
            className="w-full mt-4 py-2 text-[#96fdbf] hover:text-[#7fdba7] 
                                 transition-colors duration-300 text-sm border border-[#96fdbf]/30 
                                 rounded-lg hover:border-[#96fdbf]"
          >
            {isExpanded
              ? "Show Less"
              : `Show ${proposals.length - 3} More Proposals`}
          </button>
        )}
      </div>
    </div>
  );
};

const BuyYapVotes = () => {
  const { address, isConnected } = useAccount();

  const { loading, error, data } = useQuery(YAP_PROPOSALS_QUERY, {
    client,
  });
  const groupedProposals = useMemo(() => {
    if (!data?.yapProposals) return [];

    // Sort proposals by value (required_votes * price_per_vote)
    const sortedProposals = [...data.yapProposals].sort((a, b) => {
      const valueA = Number(a.required_votes) * Number(a.price_per_vote);
      const valueB = Number(b.required_votes) * Number(b.price_per_vote);
      return valueB - valueA;
    });

    // Group by end_timestamp
    const groups = sortedProposals.reduce((acc, proposal) => {
      const date = new Date(Number(proposal.end_timestamp));
      const dateKey = date.toISOString().split("T")[0];
      if (!acc[dateKey]) acc[dateKey] = [];
      acc[dateKey].push(proposal);
      return acc;
    }, {});

    // Sort groups by date
    return Object.entries(groups)
      .sort(([dateA], [dateB]) => new Date(dateA) - new Date(dateB))
      .map(([date, proposals]) => ({
        title: new Date(date).toLocaleDateString("en-US", {
          weekday: "long",
          year: "numeric",
          month: "long",
          day: "numeric",
        }),
        proposals,
      }));
  }, [data]);

  return (
    <div
      className="bg-black text-[#96fdbf] min-h-screen flex flex-col justify-between"
      style={{ fontFamily: "var(--body-text-font-family)" }}
    >
      <div className="flex flex-col items-center justify-between w-full">
        <Navbar />
        <InfoBar />
      </div>

      <div className="max-w-full">
        <div className="max-w-4xl mx-auto px-4 mt-12">
          {/* Instructions Section */}
          <div className="mb-8 bg-[#343434] rounded-lg shadow-lg p-8">
            <h2 className="text-2xl font-bold text-[#96fdbf] mb-6">
              How it works
            </h2>
            <div className="grid grid-cols-1 gap-6">
              {[
                {
                  step: "1",
                  title: "Choose Your Project",
                  description:
                    "Select the project you want to buy Yap votes for.",
                },
                {
                  step: "2",
                  title: "Set Your Budget",
                  description:
                    "Navigate to add budget to decide your price per vote and set your total limit.",
                },
                {
                  step: "3",
                  title: "Smart Contract Distribution",
                  description:
                    "Your budget goes to LobbyFi Smart Contract and Yap voters are rewarded for their validated votes.",
                },
                {
                  step: "4",
                  title: "Refund Policy",
                  description:
                    "Any unused budget can be claimed back after 30 days.",
                },
              ].map((item) => (
                <div
                  key={item.step}
                  className="flex items-start space-x-4 bg-black/30 p-6 rounded-lg border border-[#96fdbf]/30"
                >
                  <div className="w-8 h-8 bg-[#96fdbf] text-black rounded-full flex items-center justify-center font-bold flex-shrink-0">
                    {item.step}
                  </div>
                  <div>
                    <h3 className="text-lg font-bold text-[#96fdbf] mb-2">
                      {item.title}
                    </h3>
                    <p className="text-gray-400">{item.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {loading ? (
            <div className="bg-[#343434] rounded-lg shadow-lg p-5 mt-12">
              <p className="text-center">Loading proposals...</p>
            </div>
          ) : error ? (
            <div className="bg-[#343434] rounded-lg shadow-lg p-5 mt-12">
              <p className="text-center text-red-500">
                Error loading proposals
              </p>
            </div>
          ) : (
            groupedProposals.map(({ title, proposals }) => (
              <div
                key={title}
                className="bg-[#343434] rounded-lg shadow-lg p-5 mt-12"
              >
                <YapProposalGroup title={title} proposals={proposals} />
              </div>
            ))
          )}
        </div>
      </div>

      {/* Footer */}
      <div className="flex flex-col items-end justify-center w-full mt-24">
        <div className="flex justify-between items-center w-full px-[2px] py-2 bg-black">
          <p className="text-xs sm:text-base font-body-text text-[#96fdbf] tracking-[1.00px] leading-[20px]">
            © 2025 LobbyFi. All rights reserved.
          </p>
        </div>
        <div className="w-full bg-black">
          <img src={VectorGrid} alt="Retro Vector Grid" className="w-full" />
        </div>
      </div>
    </div>
  );
};

export default BuyYapVotes;
